import React, { useState } from 'react';

import { ImageChamp, NameChamp } from 'components/Image';
import { kdaBadge } from 'functions/common';

const PlayStatistic = ({ result }) => {
  const { early_game_stats, general_stars, played_champion_stats } = result;
  const allKillHeatmap = early_game_stats.stats_side_all.kill_heatmap.kills;
  const blueKillHeatmap = early_game_stats.stats_side_blue.kill_heatmap.kills;
  const redKillHeatmap = early_game_stats.stats_side_red.kill_heatmap.kills;
  const allDeathHeatmap = early_game_stats.stats_side_all.death_heatmap.deaths;
  const blueDeathHeatmap = early_game_stats.stats_side_blue.death_heatmap.deaths;
  const redDeathHeatmap = early_game_stats.stats_side_red.death_heatmap.deaths;

  const getCalcAvg = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  return (
    <>
      <div className="player_statis">
        <div className="statis_cge">
          <div className="played ui_box">
            <div className="ui_bullet">
              <h3>Champion Played</h3>
            </div>
            <table className="ui_table">
              <thead>
                <tr>
                  <th className="normal">Champion</th>
                  <th className="normal">Games</th>
                  <th className="normal">Win Rate</th>
                  <th className="normal">KDA</th>
                  <th className="normal">CS/M</th>
                  <th className="normal">GD@15</th>
                  <th className="normal">DPM</th>
                  <th className="normal bdr_none">KP %</th>
                  <th className="normal bdl_none">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {played_champion_stats.played_champion_stats_list.map((item, index) => (
                  <React.Fragment key={index}>
                    <RowItem item={item} />
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="statgame ui_box">
            <div className="ui_bullet">
              <h3>General Stars</h3>
            </div>
            <table className="ui_table">
              <thead>
                <tr>
                  <th className="normal">Side</th>
                  <th className="normal">Games</th>
                  <th className="normal">Win</th>
                  <th className="normal">Lose</th>
                  <th className="normal">Win Rate</th>
                  <th className="normal">K</th>
                  <th className="normal">D</th>
                  <th className="normal">A</th>
                  <th className="normal">KDA</th>
                  <th className="normal">CS/M</th>
                  <th className="normal">G/M</th>
                  <th className="normal">GD@15</th>
                  <th className="normal">KP %</th>
                  <th className="normal">GS %</th>
                  <th className="normal">DS %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="all">All</td>
                  <td>{general_stars.stats_side_all.games}</td>
                  <td>{general_stars.stats_side_all.wins}</td>
                  <td>{general_stars.stats_side_all.losses}</td>
                  <td className="w80">
                    <div className="ui_winrate all">
                      <span
                        className="win"
                        style={{ width: `${general_stars.stats_side_all.win_rate}%` }}
                      >
                        {general_stars.stats_side_all.win_rate}%
                      </span>
                    </div>
                  </td>
                  <td>{general_stars.stats_side_all.kills}</td>
                  <td>{general_stars.stats_side_all.deaths}</td>
                  <td>{general_stars.stats_side_all.assists}</td>
                  <td>
                    <div className="ui_tooltip">
                      <div className="select_area">
                        {kdaBadge(general_stars.stats_side_all.kda)}
                      </div>
                      {/* <div className="view_area small">
                        <TooltipKda />
                      </div> */}
                    </div>
                  </td>
                  <td>{general_stars.stats_side_all.cspm}</td>
                  <td>{general_stars.stats_side_all.gpm}</td>
                  <td>{general_stars.stats_side_all.gold_diff_15}</td>
                  <td>{general_stars.stats_side_all.kp}%</td>
                  <td>{general_stars.stats_side_all.gold_per}%</td>
                  <td>{general_stars.stats_side_all.dmg_per}%</td>
                </tr>
                <tr>
                  <td className="blue">Blue</td>
                  <td>{general_stars.stats_side_blue.games}</td>
                  <td>{general_stars.stats_side_blue.wins}</td>
                  <td>{general_stars.stats_side_blue.losses}</td>
                  <td className="w80">
                    <div className="ui_winrate blue">
                      <span
                        className="win"
                        style={{ width: `${general_stars.stats_side_blue.win_rate}%` }}
                      >
                        {general_stars.stats_side_blue.win_rate}%
                      </span>
                    </div>
                  </td>
                  <td>{general_stars.stats_side_blue.kills}</td>
                  <td>{general_stars.stats_side_blue.deaths}</td>
                  <td>{general_stars.stats_side_blue.assists}</td>
                  <td>
                    <div className="ui_tooltip">
                      <div className="select_area">
                        {kdaBadge(general_stars.stats_side_blue.kda)}
                      </div>
                      {/* <div className="view_area small">
                        <TooltipKda />
                      </div> */}
                    </div>
                  </td>
                  <td>{general_stars.stats_side_blue.cspm}</td>
                  <td>{general_stars.stats_side_blue.gpm}</td>
                  <td>{general_stars.stats_side_blue.gold_diff_15}</td>
                  <td>{general_stars.stats_side_blue.kp}%</td>
                  <td>{general_stars.stats_side_blue.gold_per}%</td>
                  <td>{general_stars.stats_side_blue.dmg_per}%</td>
                </tr>
                <tr>
                  <td className="red">Red</td>
                  <td>{general_stars.stats_side_red.games}</td>
                  <td>{general_stars.stats_side_red.wins}</td>
                  <td>{general_stars.stats_side_red.losses}</td>
                  <td className="w80">
                    <div className="ui_winrate red">
                      <span
                        className="win"
                        style={{ width: `${general_stars.stats_side_red.win_rate}%` }}
                      >
                        {general_stars.stats_side_red.win_rate}%
                      </span>
                    </div>
                  </td>
                  <td>{general_stars.stats_side_red.kills}</td>
                  <td>{general_stars.stats_side_red.deaths}</td>
                  <td>{general_stars.stats_side_red.assists}</td>
                  <td>
                    <div className="ui_tooltip">
                      <div className="select_area">
                        {kdaBadge(general_stars.stats_side_red.kda)}
                      </div>
                      {/* <div className="view_area small">
                        <TooltipKda />
                      </div> */}
                    </div>
                  </td>
                  <td>{general_stars.stats_side_red.cspm}</td>
                  <td>{general_stars.stats_side_red.gpm}</td>
                  <td>{general_stars.stats_side_red.gold_diff_15}</td>
                  <td>{general_stars.stats_side_red.kp}%</td>
                  <td>{general_stars.stats_side_red.gold_per}%</td>
                  <td>{general_stars.stats_side_red.dmg_per}%</td>
                </tr>
              </tbody>
            </table>
            <div className="ui_bullet">
              <h3>Early Game</h3>
            </div>
            <div className="statis_ekd">
              <div className="early ui_box">
                <div className="ui_box_tit">
                  <h4>Early Statistics at 15 Min</h4>
                </div>
                <div className="ui_box_con gry">
                  <div className="t_head">
                    <div className="row">
                      <span className="t_col1">Stats</span>
                      <span className="t_col2">All</span>
                      <span className="t_col3">Blue</span>
                      <span className="t_col4">Red</span>
                    </div>
                  </div>
                  <div className="t_body">
                    <div className="row">
                      <div className="t_col1">
                        <p>Kills</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.kills.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.kills.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.kills.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Deaths</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.deaths.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.deaths.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.deaths.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Assists</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.assists.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.assists.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.assists.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>KDA</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.kda.toFixed(2)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.kda.toFixed(2)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.kda.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Kill Participation</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.kp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.kp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.kp.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>CS per (Min)</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.cspm.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.cspm.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.cspm.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Ahead in CS</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.ahead_in_cs.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.ahead_in_cs.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.ahead_in_cs.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>CS Differential</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.cs_diff.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.cs_diff.toFixed(1)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.cs_diff.toFixed(1)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Ahead in Gold</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.ahead_in_gold.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.ahead_in_gold.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.ahead_in_gold.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Gold Differential</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.gold_diff.toFixed(0)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.gold_diff.toFixed(0)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.gold_diff.toFixed(0)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>Ahead in XP</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.ahead_in_xp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.ahead_in_xp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.ahead_in_xp.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>XP Differential</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.xp_diff.toFixed(0)}
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.xp_diff.toFixed(0)}
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.xp_diff.toFixed(0)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>First Blood Participation</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.fbp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.fbp.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.fbp.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="t_col1">
                        <p>First Blood Victim</p>
                      </div>
                      <div className="t_col2">
                        <span className="per_color">
                          {early_game_stats.stats_side_all.fbv.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col3">
                        <span className="per_color">
                          {early_game_stats.stats_side_blue.fbv.toFixed(1)}%
                        </span>
                      </div>
                      <div className="t_col4">
                        <span className="per_color">
                          {early_game_stats.stats_side_red.fbv.toFixed(1)}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="killdeath ui_box">
                <div className="ui_box_tit">
                  <h4>Kill Heatmap for 15 Min</h4>
                </div>
                <div className="ui_box_con pd0">
                  <div className="row all">
                    <div className="info">
                      <h5>All</h5>
                      <div className="avg">
                        Avg. Kills for 15m : <span>{getCalcAvg(allKillHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {allKillHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_all.kill_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_all.kill_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="row blue">
                    <div className="info">
                      <h5>Blue</h5>
                      <div className="avg">
                        Avg. Kills for 15m : <span>{getCalcAvg(blueKillHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {blueKillHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_blue.kill_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_blue.kill_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="row red">
                    <div className="info">
                      <h5>Red</h5>
                      <div className="avg">
                        Avg. Kills for 15m : <span>{getCalcAvg(redKillHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {redKillHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_red.kill_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_red.kill_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="ui_box_tit">
                  <h4>Death Heatmap for 15 Min</h4>
                </div>
                <div className="ui_box_con pd0">
                  <div className="row all">
                    <div className="info">
                      <h5>All</h5>
                      <div className="avg">
                        Avg. Deaths for 15m : <span>{getCalcAvg(allDeathHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {allDeathHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_all.death_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_all.death_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="row blue">
                    <div className="info">
                      <h5>Blue</h5>
                      <div className="avg">
                        Avg. Deaths for 15m : <span>{getCalcAvg(blueDeathHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {blueDeathHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_blue.death_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_blue.death_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="row red">
                    <div className="info">
                      <h5>Red</h5>
                      <div className="avg">
                        Avg. Deaths for 15m : <span>{getCalcAvg(redDeathHeatmap).toFixed(4)}</span>
                      </div>
                    </div>
                    <div className="heatmap">
                      {redDeathHeatmap.map((item, index) => (
                        <span
                          className={`square ${
                            item <= early_game_stats.stats_side_red.death_heatmap.color_cond_1
                              ? 'light'
                              : item >= early_game_stats.stats_side_red.death_heatmap.color_cond_2
                              ? 'dark'
                              : 'normal'
                          }`}
                          key={index}
                        >
                          <i>{index + 1}</i>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RowItem = ({ item }) => {
  const [openToggle, setOpenToggle] = useState(false);
  const onToggle = () => setOpenToggle((prev) => !prev);

  return (
    <>
      <tr className={`tr_normal ${openToggle && 'selected'}`} onClick={onToggle}>
        <td>
          {item.champion_id === 0 ? (
            'All'
          ) : (
            <div className="champs_vs forward">
              <ImageChamp champId={item.champion_id} />
              <p>
                <NameChamp champId={item.champion_id} />
              </p>
            </div>
          )}
        </td>
        <td>{item.games}</td>
        <td className="w80">
          <div className="ui_winrate all">
            <span className="win" style={{ width: `${item.win_rate}%` }}>
              {item.win_rate}%
            </span>
          </div>
        </td>
        <td>
          <div className="ui_tooltip">
            <div className="select_area">{kdaBadge(item.kda)}</div>
            {/* <div className="view_area small">
              <TooltipKda />
            </div> */}
          </div>
        </td>
        <td>{item.cspm}</td>
        <td>{item.gold_diff_15}</td>
        <td>{item.dpm}</td>
        <td>{item.kp}</td>
        <td className="more">
          <span className="arrow">더보기</span>
        </td>
      </tr>
      {openToggle &&
        item.enemy_champion_stats_list.map((itm, idx) => {
          return (
            <tr className="detail" key={idx}>
              <td>
                <div className="champs_vs reverse">
                  <ImageChamp champId={itm.champion_id} />
                  <p>VS</p>
                </div>
              </td>
              <td>{itm.games}</td>
              <td className="w80">
                <div className="ui_winrate all">
                  <span className="win" style={{ width: `${itm.win_rate}%` }}>
                    {itm.win_rate}%
                  </span>
                </div>
              </td>
              <td>
                <div className="ui_tooltip">
                  <div className="select_area">{kdaBadge(itm.kda)}</div>
                  {/* <div className="view_area small">
                    <TooltipKda />
                  </div> */}
                </div>
              </td>
              <td>{itm.cspm}</td>
              <td>{itm.gold_diff_15}</td>
              <td>{itm.dpm}</td>
              <td>{itm.kp}</td>
              <td></td>
            </tr>
          );
        })}
    </>
  );
};

export default PlayStatistic;
