import styled from 'styled-components';

// 인증 컴포넌트
export const AuthContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url('https://ddragon.leagueoflegends.com/cdn/img/champion/splash/Zeri_0.jpg')
    no-repeat center;
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #7f92b7 0%, #382551 100%);
    opacity: 0.78;
    z-index: 50;
  }

  .form_login {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    width: 450px;
    padding: 75px 60px 60px;
    background: var(--color-white);
    box-shadow: 0px 6px 24px #000000b3;

    h1 {
      margin-bottom: 45px;
      text-align: center;
    }

    .inp_wrap {
      display: flex;
      flex-direction: column;
      input {
        width: 100%;
        height: 48px;
        padding: 0 13px;
        font-size: 15px;
        font-weight: 500;
        color: #3e3e46;
        border: 0;
        border-bottom: 1px solid #a5a3db;
        transition: border-bottom 0.2s ease;
        &::-webkit-input-placeholder {
          font-weight: 400;
          color: rgba(62, 62, 70, 0.5);
        }
        &:focus {
          border-bottom: 2px solid #6a5ba9;
        }
      }
      input + input {
        margin-top: 22px;
      }
    }

    .check_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      .forgot {
        color: #6a5ba9;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .submit_wrap {
      margin-top: 40px;
      text-align: center;
      .submit {
        width: 100%;
        height: 42px;
        background: linear-gradient(90deg, #6a5ba9 0%, #9b67be 100%);
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 500;
        color: var(--color-white);
      }
      a {
        display: inline-block;
        margin-top: 20px;
        font-size: 14px;
        color: #6a5ba9;
        text-decoration: underline;
      }
    }
  }
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 18px 15px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
  }

  .name {
    margin-left: 17px;
    h3 {
      font-size: 26px;
      font-weight: 500;
    }
  }

  .btn {
    margin-left: 19px;
    .ui_btn {
      min-width: 96px;
      height: 30px;
    }
  }

  .title {
    h2 {
      font-size: 26px;
      font-weight: 700;
      color: #6a5bab;
    }
  }
`;

export const SubFilterContainer = styled.div`
  .forms {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #dad9ef;
    border-radius: 6px;
  }

  .form {
    flex: 1;
    .row {
      display: flex;
      align-items: center;
      dl {
        display: flex;
        align-items: center;
        &.active {
          opacity: 1;
        }
        &.notactive {
          opacity: 0.5;
          dd {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      dl + dl {
        margin-left: 35px;
      }
      dt {
        margin-right: 22px;
        font-weight: 700;
        color: var(--color-primary-bold);
      }
      dd {
        position: relative;
        display: flex;
        align-items: center;
      }
      dd + dd {
        margin-left: 12px;
      }
    }
    .row + .row {
      margin-top: 10px;
    }
    .ui_form + .ui_form {
      margin-left: 12px;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 140px;
    .ui_btn {
      height: 32px;
    }
    .ui_btn + .ui_btn {
      margin-left: 10px;
    }
  }

  .rdrDateRangePickerWrapper {
    background: var(--color-white);
    border: 1px solid var(--color-primary-normal);
    border-radius: 3px;
  }
  .rdrDefinedRangesWrapper {
    width: 115px;
    border-right: 1px solid #dad9ef;
  }
  .rdrInputRanges {
    display: none;
  }
  .rdrStaticRanges {
    padding: 10px 0;
  }
  .rdrStaticRange {
    border: 0;
  }
  .rdrStaticRangeSelected {
    .rdrStaticRangeLabel {
      font-weight: 400;
      color: var(--color-white);
      background: var(--color-primary-bold);
    }
  }
  .rdrStaticRangeLabel {
    padding: 12px;
    line-height: 1;
    color: var(--color-primary-bold);
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
  .rdrCalendarWrapper {
    width: 460px;
  }
  .rdrMonthName {
    color: var(--color-black);
    text-align: center;
  }
  .rdrStartEdge {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: var(--color-primary-bold);
  }
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .rdrEndEdge {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: var(--color-primary-bold);
  }
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: var(--color-black);
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--color-primary-bold);
  }
`;

export const SubTableContainer = styled.div`
  position: relative;

  .table_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 28px 0 6px;

    .show_hide {
      button {
        width: 28px;
        height: 28px;
        background: #fff;
        border: 1px solid #a4a4b1;
        border-radius: 6px;
      }
      button + button {
        margin-left: 6px;
      }
    }

    .totals {
      display: flex;
      align-items: center;
      dl {
        display: flex;
        align-items: flex-end;
        margin-right: 25px;
      }
      dt {
        color: rgba(49, 49, 49, 0.7);
      }
      dd {
        margin-left: 3px;
        font-weight: 500;
        color: rgba(49, 49, 49, 1);
      }
      select {
        display: flex;
        align-items: center;
        width: 100px;
        height: 26px;
        padding: 0 28px 0 10px;
        border: 1px solid var(--color-primary-normal);
        border-radius: 3px;
        background-repeat: no-repeat;
        background-position: right 6px center;
        background-image: url('/images/icon_drop_down.svg');
        cursor: pointer;
      }
    }
  }

  table {
    width: 100%;
    thead {
      background: #eaeaef;
      border-bottom: 2px solid #535358;
      th {
        position: relative;
        height: 35px;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ccccd4;
        font-weight: 700;
      }
      .ban_pick {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px 0 11px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          background: #a4a4b1;
          border: 1px solid #535358;
          border-radius: 6px;
        }
      }
      .col_box {
        background: rgba(255, 255, 255, 0.7);
      }
    }
    tbody {
      background: #fff;
      tr {
        &.win {
          background: #f8fcff;
          &:hover {
            background: #b9dcff;
            border-top: 2px solid #629ad2;
            border-bottom: 2px solid #629ad2;
          }
        }
        &.lose {
          background: #fff6f8;
          &:hover {
            background: #ffccd7;
            border-top: 2px solid #eb8499;
            border-bottom: 2px solid #eb8499;
          }
        }
      }
      td {
        position: relative;
        height: 35px;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #ccccd4;
        &:nth-child(7) {
          border-right: 1px solid #ccccd4;
        }
        &.tdBansPicks {
          border-left: 0;
          border-right: 0;
          &:nth-child(5n + 2) {
            border-right: 1px solid #ccccd4;
          }
        }
      }
      .player {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: 80px;
        padding: 0 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
      }
      .teams {
        display: flex;
        align-items: center;
        margin: 0 4px;
      }
      .team_logo {
        width: 24px;
        margin-right: 5px;
      }
    }
    .col_box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      &.lane {
        padding-left: 10px;
        justify-content: flex-start;
      }
      .lane_name {
        display: inline-block;
        margin-left: 10px;
        font-weight: 500;
      }
    }
    .dummyList {
      position: relative;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0 4px;
    }
    .selectList {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0 4px;
    }
  }

  .detailCell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #a4a4b1;
    border: 1px solid #a4a4b1;
  }

  .checkCell {
    display: flex;
    align-items: center;
    padding-left: 12px;
    i {
      position: absolute;
      top: 0;
      left: -1px;
      display: block;
      width: 8px;
      height: 100%;
      &.win {
        background: #629ad2;
      }
      &.lose {
        background: #eb8499;
      }
    }
  }

  .imgCell {
    width: 26px;
    margin: 0 2px;
  }

  .popup {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border: 1px solid #999ebf;
    border-radius: 3px;
    width: 214px;
    z-index: 10;

    &_top {
      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &_close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      span {
        position: absolute;
        left: 6px;
        display: block;
        width: 18px;
        height: 1px;
        background-color: var(--color-primary-bold);
      }
      .line1 {
        top: 5px;
        transform: translateY(9px) rotate(-45deg);
      }
      .line2 {
        bottom: 5px;
        transform: translateY(-8px) rotate(45deg);
      }
    }

    &_selected {
      position: relative;
      border-top: 1px solid #999ebf;
      padding: 11px 10px;
      .imgCell {
        width: 32px;
        height: 32px;
      }
      li {
        position: relative;
        width: 32px;
        height: 32px;
        margin: 0 2px;
        .imgCell {
          margin: 0;
        }
      }
      .delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        background: rgba(227, 21, 91, 0.8);
        cursor: pointer;
        span {
          position: absolute;
          left: 3px;
          display: block;
          width: 10px;
          height: 2px;
          background-color: var(--color-white);
        }
        .line1 {
          top: 2px;
          transform: translateY(5px) rotate(-45deg);
        }
        .line2 {
          bottom: 2px;
          transform: translateY(-5px) rotate(45deg);
        }
      }
    }

    &_list {
      .filter {
        border-top: 1px solid #999ebf;
        border-bottom: 1px solid #999ebf;
        background: #eaeaef;
        padding: 7px 14px;
      }
      .search {
        position: relative;
        input[type='text'] {
          width: 100%;
          height: 32px;
          padding: 0 28px 0 10px;
          background: #fff;
          border: 1px solid #999ebf;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
        }
        button {
          position: absolute;
          top: 50%;
          right: 6px;
          margin-top: -8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          cursor: auto;
        }
      }
      .tabs {
        display: flex;
        margin-top: 7px;
        button {
          width: 100%;
          height: 32px;
          color: #6a5aac;
          background: #fff;
          border: 1px solid #6a5aac;
          &:first-of-type {
            border-radius: 6px 0px 0px 6px;
          }
          &:last-of-type {
            border-radius: 0px 6px 6px 0px;
          }
          &.active {
            font-weight: 500;
            color: #fff;
            background: #6a5aac;
          }
        }
      }
      .result {
        height: 320px;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          width: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #e0dcec;
          border-radius: 3px;
        }
        ::-webkit-scrollbar-track {
          background-color: #fff;
        }
        ul {
          padding: 7px 0;
        }
        li {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 3px 15px;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            background: #ebe7fc;
          }
        }
        p {
          margin-left: 10px;
          font-weight: 500;
        }
        .imgCell {
          width: 32px;
        }
      }
    }

    &_btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 14px;
      background: #eaeaef;
      button {
        min-width: 90px;
        height: 32px;
      }
    }
  }
`;

export const SubDetailContainer = styled.div`
  .detail_title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    background: var(--color-white);
    border: 1px solid #ccccd4;
    border-bottom: 3px solid #ccccd4;

    .team {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        font-size: 22px;
        font-weight: 700;
        &.win {
          color: #6dc9a7;
        }
        &.lose {
          color: #b7b7b7;
        }
      }
      .team_name {
        margin: 0 33px;
        font-size: 36px;
        font-weight: 700;
      }
      .team_logo {
        width: 48px;
      }
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 86px;
      p {
        font-size: 36px;
        font-weight: 700;
        &.win {
          color: #5682db;
        }
        &.lose {
          color: #de4469;
        }
      }
      b {
        display: inline-block;
        margin: 5px 17px 0;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .bottom {
      position: absolute;
      top: 100%;
      left: 50%;
      min-width: 260px;
      height: 28px;
      transform: translateX(-50%);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
        display: block;
        width: 100%;
        height: 0;
        border-top: 32px solid #ccccd4;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 50%;
        z-index: 15;
        transform: translateX(-50%);
        display: block;
        width: calc(100% - 2px);
        height: 0;
        border-top: 28px solid #f1f3f8;
        border-left: 21px solid transparent;
        border-right: 21px solid transparent;
      }
      .box {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .obj {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          margin: 0 1.5px;
        }
      }
      .time {
        margin: 0 6.5px;
        span {
          font-size: 14px;
          color: rgba(49, 49, 49, 0.75);
        }
      }
    }
  }

  .detail_summary {
    margin-top: 45px;

    .ui_table {
      table-layout: fixed;
    }
  }

  .detail_ed {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .economy {
      width: 1004px;
      .t_head {
        margin-bottom: 13px;
        .team {
          padding: 0 7px;
          span {
            display: block;
          }
        }
      }
      .t_body {
        .team {
          height: 75px;
          padding: 7px;
          border-radius: 6px;
        }
        .blue {
          background: #c6e5f9;
        }
        .red {
          background: #efdee0;
        }
      }
      .row {
        display: flex;
        align-items: center;
      }
      .row + .row {
        margin-top: 7px;
      }
      .team {
        flex: 1;
        display: flex;
        align-items: center;
        text-align: center;
      }
      .center {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        font-size: 0;
        img {
          width: 24px;
        }
      }
      .t_col1 {
        width: 95px;
      }
      .t_col2 {
        width: 100px;
      }
      .t_col3 {
        width: 55px;
      }
      .t_col4 {
        width: 90px;
      }
      .t_col5 {
        width: 105px;
      }
    }

    .draftorder {
      width: 426px;
      .title {
        display: flex;
        align-items: center;
        height: 83px;
        .team {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .team_logo {
            width: 42px;
          }
          h5 {
            margin: 0 15px;
            font-size: 20px;
            font-weight: 700;
          }
        }
        .center {
          margin: 0 18px;
          text-align: center;
          b {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .result {
        display: flex;
        width: 100%;
        height: 36px;
        border-top: 1px solid #ccccd4;
        .team {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
        }
        .team + .team {
          border-left: 1px solid #ccccd4;
        }
        .win {
          color: rgba(49, 49, 49, 1);
          background: var(--color-white);
        }
        .lose {
          color: rgba(49, 49, 49, 0.5);
          background: #eaeaef;
        }
      }
      .order {
        .row {
          display: flex;
          align-items: center;
          width: 100%;
          height: 34px;
          border-bottom: 1px solid #ccccd4;
          &:last-of-type {
            border-bottom: 0;
          }
        }
        .ban {
          background: #f5f6fb;
          border-top: 1px solid #535358;
          border-bottom: 1px solid #535358;
          .img_champion {
            opacity: 0.5;
          }
          .center {
            p {
              font-weight: 500;
              color: #bc5873;
            }
          }
        }
        .pick {
          background: var(--color-white);
          .center {
            p {
              width: 50%;
              height: 100%;
              img {
                width: 24px;
              }
            }
            p + p {
              border-left: 1px solid #d1cdd9;
            }
          }
        }
        .center {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 70px;
          height: 34px;
          border-left: 1px solid #a4a4b1;
          border-right: 1px solid #a4a4b1;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .team {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 5px;
          p {
            margin: 0 8px;
            font-size: 14px;
          }
        }
        .img_champion {
          width: 26px;
        }
      }
    }
  }

  .detail_ge {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .graph {
      width: 888px;
      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btns {
          display: flex;
          align-items: center;
          .ui_btn + .ui_btn {
            margin-left: 6px;
          }
        }
        .switch {
          display: flex;
          align-items: center;
          .ui_switch {
            margin-left: 6px;
          }
        }
      }
      .panel {
        margin-top: 30px;
        .xAxis_label {
          display: block;
          width: 32px;
          height: 32px;
          span {
            font-size: 0;
          }
          .imgCell {
            border-radius: 16px;
          }
        }
        .tootip_name {
          text-transform: capitalize;
        }
      }
    }

    .jungletracking {
      width: 542px;
      .filter {
        display: flex;
        align-items: center;
        .ui_form + .ui_form {
          margin-left: 24px;
        }
      }
      .panel {
        margin-top: 14px;
        padding: 6px;
        img {
          width: 100%;
        }
      }
    }
  }

  .detail_gold {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .teamgold {
      width: 100%;
      .plotgraph {
        margin-left: 66px;
        margin-right: 10px;
      }
      .timeline {
        margin-left: 10px;
        margin-right: 15px;
        border-left: 1px solid #535358;
        border-right: 1px solid #535358;
        .team {
          &.blue {
            background: linear-gradient(
              90deg,
              rgba(183, 212, 255, 0.5) 0%,
              rgba(183, 212, 255, 0.15) 100%
            );
            .line {
              .circle {
                background: #5f82e2;
              }
              .tooltip {
                &::before {
                  border-top: 8px solid #e9f2ff;
                }
                ul {
                  background: #e9f2ff;
                  border-left: 4px solid #5f82e2;
                }
              }
            }
            .line + .line {
              border-top: 1px solid #a5c5fb;
            }
          }
          &.red {
            border-top: 1px solid #8388a5;
            background: linear-gradient(
              270deg,
              rgba(255, 187, 195, 0.15) 0%,
              rgba(255, 187, 195, 0.5) 100%
            );
            .line {
              .circle {
                background: #f54a78;
              }
              .tooltip {
                &::before {
                  border-top: 8px solid #fff3f5;
                }
                ul {
                  background: #fff3f5;
                  border-left: 4px solid #f54a78;
                }
              }
            }
            .line + .line {
              border-top: 1px solid #e9a0af;
            }
          }
        }
        .line {
          display: flex;
          align-items: center;
          width: 100%;
          dt {
            width: 90px;
            padding: 0 15px;
            font-weight: 500;
            color: rgba(49, 49, 49, 0.93);
          }
          dd {
            position: relative;
            flex: 1;
            display: flex;
            align-items: center;
            height: 32px;
            border-left: 1px dashed #6f7bcc;
          }
          .obj {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            cursor: auto;
          }
          .imgCell {
            width: 18px;
          }
          .imgCell + .imgCell {
            margin-left: 2px;
          }
          .kill {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .circle {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              margin-left: -10px;
              opacity: 1;
              cursor: auto;
              &.size1 {
                width: 8px;
                height: 8px;
                margin-left: -4px;
                opacity: 0.2;
              }
              &.size2 {
                width: 12px;
                height: 12px;
                margin-left: -6px;
                opacity: 0.4;
              }
              &.size3 {
                width: 16px;
                height: 16px;
                margin-left: -8px;
                opacity: 0.6;
              }
              &.size4 {
                width: 18px;
                height: 18px;
                margin-left: -9px;
                opacity: 0.8;
              }
            }
            .circle + .tooltip {
              margin-left: -5px;
            }
            .circle.size1 + .tooltip {
              margin-left: -2px;
            }
            .circle.size2 + .tooltip {
              margin-left: -3px;
            }
            .circle.size3 + .tooltip {
              margin-left: -4px;
            }
            .circle.size4 + .tooltip {
              margin-left: -4.5px;
            }
            .tooltip {
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              min-width: 156px;
              margin-bottom: 10px;
              box-shadow: 0px 2px 3px #0000004d;
              border: 1px solid #999ebf;
              &::before {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0px;
                height: 0px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
              }
              ul {
                padding: 10px;
              }
              li {
                display: flex;
                align-items: center;
              }
              li + li {
                margin-top: 8px;
              }
              .imgCell {
                width: 28px;
                border: 1px solid #242424;
                border-radius: 20px;
              }
              .versus {
                margin: 0 10px;
                img {
                  width: 18px;
                }
              }
            }
          }
        }
      }
    }
  }

  .detail_popup {
    position: relative;

    .dimm,
    .content {
      animation: modal-show 0.5s;
    }
    .dimm {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(2px);
    }
    .content {
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 20;
      transform: translate(-50%, -50%);
      width: 720px;
      padding: 15px;
      background: #f4f4f5;
      box-shadow: 0px 6px 10px #0000004d;
      border: 1px solid #5d9ceb;
      h3 {
        margin-bottom: 10px;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 700;
      }
      .build {
        background: #fff;
        border: 1px solid #cad7e2;

        &.items {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px 0;
          min-height: 150px;
          .item {
            position: relative;
            padding: 0 12px;
            margin: 10px 0;
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              z-index: 5;
              display: block;
              width: 100%;
              height: 16px;
              margin-top: -16px;
              background: #cad7e2;
            }
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              right: -6px;
              z-index: 8;
              display: block;
              width: 9px;
              height: 8px;
              margin-top: -12px;
              background: url('/images/icon_arrow_up_w.svg') no-repeat center;
            }
            &:first-of-type {
              padding-left: 30px;
            }
            &:last-of-type {
              padding-right: 30px;
              &::before {
                display: none;
              }
            }
            dl {
              position: relative;
              z-index: 10;
              dt {
                display: flex;
                align-items: center;
              }
              dd {
                margin-top: 4px;
                font-size: 10px;
                font-weight: 500;
                text-align: center;
              }
              span {
                position: relative;
                &.sold::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.3) url('/images/icon_sold.svg') center no-repeat;
                }
              }
            }
            .imgCell {
              width: 28px;
              margin: 0 1px;
            }
          }
        }
        &.runes {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          min-height: 280px;
          > div {
            width: 224px;
            margin: 0 8px;
            padding: 0 8px;
            border: 1px solid #cad7e2;
          }
          .tit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
            font-size: 12px;
            img {
              width: 32px;
              margin-right: 12px;
            }
          }
          .list {
            padding-bottom: 20px;
            li {
              display: flex;
              align-items: center;
              justify-content: space-around;
              padding: 8px 5px;
            }
            .normal {
              opacity: 0.3;
              mix-blend-mode: luminosity;
            }
            .selected {
              opacity: 1;
            }
            .imgCell {
              width: 32px;
            }
          }
          .runes_first {
            .list {
              li {
                &:first-of-type {
                  background: #dbdbee 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  .imgCell {
                    width: 44px;
                  }
                }
              }
            }
          }
          .runes_second {
            .list {
              li {
                &:nth-of-type(1) {
                  display: none;
                }
              }
            }
          }
        }
        & + h3 {
          margin-top: 12px;
        }
      }
    }
  }

  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const SubTeamsContainer = styled.div`
  .team_summary {
    margin-top: 20px;
  }

  .team_eo {
    display: flex;
    justify-content: space-between;

    .tbl {
      width: 354px;
      dl {
        display: flex;
      }
      dl + dl {
        border-top: 1px solid #ccccd4;
      }
      dt {
        display: flex;
        align-items: center;
        flex: 1;
        height: 35px;
        padding: 0 12px;
      }
      dd {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 88px;
        height: 35px;
        padding: 0 7px;
        border-left: 1px solid #ccccd4;
        text-align: right;
      }
    }

    .graph {
      width: 1078px;
      .diff_box {
        display: flex;
      }
      .diff {
        flex: 1;
        height: 215px;
      }
      .diff + .diff {
        border-left: 1px solid #ccccd4;
      }
    }
  }

  .team_dww {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .graph {
      width: 474px;
    }
  }

  .team_winrate {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .graph {
      width: 100%;
      .diff_box {
        display: flex;
      }
      .diff {
        flex: 1;
        height: 260px;
      }
      .diff + .diff {
        border-left: 1px solid #ccccd4;
      }
    }
  }

  .bargraph {
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
      margin-bottom: 8px;
      padding: 0 20px;
      h5 {
        margin-right: 5px;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        &.all {
          color: #52a184;
        }
        &.blue {
          color: #2e7aae;
        }
        &.red {
          color: #d84f6b;
        }
      }
      p {
        font-size: 10px;
        color: rgba(49, 49, 49, 0.6);
        strong {
          font-weight: 500;
          color: rgba(49, 49, 49, 1);
        }
      }
    }
    .data_label {
      text-align: center;
      font-size: 10px;
      color: rgba(49, 49, 49, 0.6);
      strong {
        font-size: 12px;
        font-weight: 700;
        color: rgba(49, 49, 49, 1);
      }
      dt {
        span {
          font-weight: 500;
          color: rgba(49, 49, 49, 1);
        }
      }
    }
    .dragon_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      img {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

export const SubPlayersContainer = styled.div`
  .player_roster {
    position: relative;
  }

  .player_statis {
    .statis_cge {
      display: flex;
      justify-content: space-between;

      .played {
        width: 600px;
      }

      .statgame {
        width: 830px;
      }
    }

    .statis_ekd {
      display: flex;
      justify-content: space-between;

      .early {
        width: 390px;
        .t_head {
          margin-bottom: 8px;
          span {
            display: block;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .t_body {
          border: 1px solid #ccccd4;
          background: #fff;
          .row {
            height: 38px;
          }
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: break-word;
            text-align: left;
          }
        }
        .row {
          display: flex;
          align-items: center;
          padding: 0 7px;
          text-align: center;
        }
        .row + .row {
          border-top: 1px solid #ccccd4;
        }
        .t_col1 {
          flex: 1;
          min-width: 0;
        }
        .t_col2 {
          width: 60px;
          margin: 0 2.5px;
        }
        .t_col3 {
          width: 60px;
          margin: 0 2.5px;
        }
        .t_col4 {
          width: 60px;
          margin: 0 2.5px;
        }
      }

      .killdeath {
        width: 426px;
        .row {
          padding: 15px 15px 10px;
          &.all {
            .info h5 {
              color: #52a184;
            }
            .heatmap .square {
              &.light {
                background: #b8eeda;
              }
              &.normal {
                background: #6dc9a7;
              }
              &.dark {
                background: #519f7d;
              }
            }
          }
          &.blue {
            .info h5 {
              color: #2e7aae;
            }
            .heatmap .square {
              &.light {
                background: #c6e5f9;
              }
              &.normal {
                background: #6dbaee;
              }
              &.dark {
                background: #5192be;
              }
            }
          }
          &.red {
            .info h5 {
              color: #d84f6b;
            }
            .heatmap .square {
              &.light {
                background: #f6d8de;
              }
              &.normal {
                background: #e9a0af;
              }
              &.dark {
                background: #ac717d;
              }
            }
          }
        }
        .row + .row {
          border-top: 1px solid #ccccd4;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          h5 {
            font-size: 14px;
            font-weight: 500;
          }
          .avg {
            font-size: 10px;
            color: rgba(49, 49, 49, 0.6);
            span {
              font-weight: 500;
              color: rgba(49, 49, 49, 1);
            }
          }
        }
        .heatmap {
          position: relative;
          display: flex;
          padding-bottom: 17px;
          &::before {
            content: '0';
            position: absolute;
            bottom: 1px;
            left: -6px;
            display: inline-block;
            margin-top: 2px;
            width: 12px;
            font-size: 10px;
            text-align: center;
          }
          .square {
            position: relative;
            display: block;
            width: 25px;
            height: 17px;
            i {
              position: absolute;
              top: 100%;
              right: -6px;
              display: inline-block;
              width: 12px;
              margin-top: 2px;
              font-size: 10px;
              text-align: center;
            }
          }
          .square + .square {
            margin-left: 1px;
          }
        }
      }
    }
  }
`;

export const SubNotificationContainer = styled.div`
  position: relative;
  margin-top: 25px;
`;
