import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';

import { getSortedArray } from 'functions/common';

const TeamDTGCL = ({ data, title }) => {
  const value = getSortedArray(Object.values(data), 'value');
  const champion_id = getSortedArray(Object.values(data), 'champion_id');
  const version = useSelector((state) => state.image.version);
  const imageSelector = useSelector((state) => state.image.champions);

  const options = {
    chart: {
      type: 'bar',
      height: 475,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: champion_id,
      labels: {
        useHTML: true,
        formatter: function () {
          const imgObj = imageSelector.filter((item) => item.championId === `${this.value}`)[0];
          return (
            imgObj &&
            `<div class="xAxis_label">
                    <span>챔피언</span>
                    <img src="${process.env.REACT_APP_DDRAGON}cdn/${version}/img/champion/${imgObj.image_name}" class="imgCell img_champion" />
                  </div>
                `
          );
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      gridLineWidth: 1,
      gridLineColor: '#CCCCD4',
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        pointWidth: 24,
        pointPadding: 0,
        colorByPoint: true,
        colors: [
          '#6DBAEE',
          '#6DBAEE',
          '#6DBAEE',
          '#6DBAEE',
          '#6DBAEE',
          '#E9A0AF',
          '#E9A0AF',
          '#E9A0AF',
          '#E9A0AF',
          '#E9A0AF',
        ],
      },
    },
    series: [
      {
        name: title,
        data: value,
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<i></i>',
      pointFormat: '<div class="tootip_name">{series.name} : {point.y}</div>',
    },
  };

  return (
    <div className="bargraph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default TeamDTGCL;
