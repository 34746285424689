import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
  group_idx: '',
  team_alias: '',
};

export const userReducer = createSlice({
  name: 'user',
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { login, logout } = userReducer.actions;
export default userReducer.reducer;
