import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Spinner, Filter } from 'components';
import { Table } from 'components/scrim';
import { SubTitleContainer, SubFilterContainer, SubTableContainer } from 'styles/pageStyles';

const MatchHistory = () => {
  const userInfo = useSelector((state) => state.user.value);
  const teamInfo = useSelector((state) => state.team);
  const [filters] = useState({
    is_win: 0,
    select_side: 0,
    duration_interval: 0,
    start_date: '',
    end_date: '',
    patches: '',
    vs_team_alias: '',
  });
  const [result, setResult] = useState(false);

  // 매치 데이터 가져오기
  const fetchMain = async (
    { group_idx, team_alias },
    { is_win, select_side, duration_interval, start_date, end_date, patches, vs_team_alias }
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SCRIM_DATA}v1/MatchHistory/main?account_group_idx=${group_idx}&account_team_alias=${team_alias}&is_win=${is_win}&select_side=${select_side}&duration_interval=${duration_interval}&start_date=${start_date}&end_date=${end_date}&patches=${patches}&vs_team_alias=${vs_team_alias}`
    );
    setResult(res.data);
  };

  useEffect(() => {
    if (userInfo.group_idx !== '') {
      fetchMain(userInfo, filters);
    }
  }, [userInfo, filters]);

  return (
    <>
      <SubTitleContainer>
        <div className="logo">
          <img src={teamInfo.team_logo} alt={teamInfo.team_name} className="team_logo" />
        </div>
        <div className="name">
          <h3>{teamInfo.team_name}</h3>
        </div>
      </SubTitleContainer>
      <SubFilterContainer>
        {teamInfo.patch.length !== 0 && (
          <Filter filters={filters} fetchMain={fetchMain} isDetail={true} />
        )}
      </SubFilterContainer>
      {result ? (
        <SubTableContainer>
          <Table result={result.result} winRate={result.win_rate} totalGames={result.total_games} />
        </SubTableContainer>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default MatchHistory;
