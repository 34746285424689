import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  ${reset};
  :root {
    --color-white: #fff;
    --color-black: #313131;
    --color-primary-bold: #6A5AAC;
    --color-primary-normal: #999EBF;
    --color-primary-light: #F5F2FC;
  }
  * {
    box-sizing: border-box;
    outline: 0;
  }
  body {
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    color: #313131;
    line-height: 1.428;
    letter-spacing: 0;
    word-break: keep-all;
    overflow-y: scroll;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    vertical-align: top;
    max-width: 100%;
  }
  button {
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0;
  }
  input,
  button {
    font: inherit;
    padding: 0;
    vertical-align: middle;
  }
  select {
    font: inherit;
    appearance: none;
    padding: 0;
    vertical-align: middle;
  }

  .forward {
    flex-direction: row;
  }
  .reverse {
    flex-direction: row-reverse;
  }
  
  .scroll_x {
    padding-bottom: 6px;
    overflow-x: auto;
    ::-webkit-scrollbar {
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #ccccd4;
      border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
    .ui_table {
      width: 100%;
      min-width: 1400px;
    }
  }

  .ico {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    vertical-align: middle;
    font-size: 0;
    text-indent: -9999px;
    &.ico_upload {
      width: 20px;
      height: 20px;
      background-image: url('/images/icon_upload.svg');
    }
    &.ico_search {
      width: 16px;
      height: 16px;
      background-image: url('/images/icon_input_search.svg');
    }
    &.ico_del {
      width: 16px;
      height: 16px;
      background-image: url('/images/icon_input_del.svg');
    }
    &.ico_refresh_pri {
      width: 16px;
      height: 16px;
      background-image: url('/images/ico_refesh-1.svg');
    }
    &.ico_refresh_whi {
      width: 16px;
      height: 16px;
      background-image: url('/images/ico_refesh02.svg');
    }
    &.ico_change_team {
      width: 20px;
      height: 20px;
      background-image: url('/images/icon_change_team.svg');
    }
    &.ico_help {
      width: 26px;
      height: 26px;
      background-image: url('/images/btn_help.svg');
    }
    &.ico_mark_o {
      width: 14px;
      height: 14px;
      background: var(--color-white);
      border: 3px solid #6dc9a7;
      border-radius: 50%;
      // background-image: url('/images/ico_mark_o.svg');
    }
    &.ico_mark_x {
      width: 14px;
      height: 14px;
      background-image: url('/images/ico_mark_x.svg');
    }
  }

  .ui_form {
    position: relative;
    display: inline-flex;
    align-items: center;
    line-height: 1.5;

    &.single {
      width: 16px;
      height: 16px;
    }

    .ui_inp_text {
      width: 100%;
      height: 26px;
      padding: 0 22px 0 8px;
      border: 1px solid var(--color-primary-normal);
      border-radius: 6px;
      outline: none;
      transition: all 0.2s ease;
      &:focus {
        border-color: var(--color-primary-bold);
      }
      &:disabled {
        background: rgba(106, 90, 172, 0.15);
      }
      &::-webkit-input-placeholder {
        color: rgba(49, 49, 49, 0.5);
      }
    }
    .ui_inp_check,
    .ui_inp_radio {
      position: absolute;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      margin: 0;
      transform: translateY(-50%);
      opacity: 0;
      outline: 0;
      border: 0;
      z-index: 20;
      cursor: pointer;
      & + label {
        position: relative;
        display: inline-block;
        padding-left: 24px;
        color: rgba(49, 49, 49, 0.7);
        font-weight: 500;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 16px;
          height: 16px;
          transform: translateY(-50%);
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          box-sizing: border-box;
          z-index: 10;
        }
      }
      &:checked + label {
        color: rgba(49, 49, 49, 1);
      }
    }
    .ui_inp_check {
      & + label {
        &::before {
          background-image: url('/images/btn_check_off.svg');
        }
      }
      &:checked + label {
        &::before {
          background-image: url('/images/btn_check_on.svg');
        }
      }
    }
    .ui_inp_radio {
      & + label {
        &::before {
          background-image: url('/images/btn_radio_off.svg');
        }
      }
      &:checked + label {
        &::before {
          background-image: url('/images/btn_radio_on.svg');
        }
      }
    }
  }
  
  .ui_bullet {
    margin: 30px 0 7px;
    padding-bottom: 6px;
    border-bottom: 2px solid #535358;
    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 2px 8px 0;
        border-radius: 2px;
        background: #6a5aac;
      }
    }
  }
  
  .ui_switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 38px;
    height: 18px;

    .onoff {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      display: block;
      width: 100%;
      height: 100%;
      background: var(--color-white);
      border: 1px solid #999ebf;
      border-radius: 9px;
      transition: all 0.4s ease;
      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: rgba(153, 158, 191, 0.5);
        transition: left 0.2s ease;
      }
    }

    input[type='checkbox'] {
      position: relative;
      z-index: 20;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    input[type='checkbox']:checked + .onoff {
      border: 1px solid var(--color-primary-bold);
      background: var(--color-primary-bold);
      &::before {
        left: 22px;
        background: var(--color-white);
      }
    }
  }
  
  .ui_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    min-width: 100px;
    height: 36px;

    &.btn_pri {
      font-weight: 500;
      color: var(--color-white);
      background: var(--color-primary-bold);
      border-color: var(--color-primary-bold);
    }
    &.btn_sec {
      font-weight: 400;
      color: rgba(106, 90, 172, 0.6);
      background: var(--color-primary-light);
      border-color: var(--color-primary-bold);
    }
    &.btn_whi {
      font-weight: 500;
      color: var(--color-primary-bold);
      background: var(--color-white);
      border-color: var(--color-primary-bold);
    }
    &.btn_refresh {
      min-width: 32px;
      height: 32px;
    }
  }

  .ui_date {
    min-width: 180px;

    .menu_area {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      margin-top: 10px;
    }
  }

  .ui_dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-width: 200px;
    max-width: 200px;

    .select_area {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
    }

    .menu_area {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 100;
      margin-top: 10px;
      width: 100%;
      background: var(--color-white);
      border: 1px solid #6a5aac4d;
      border-radius: 3px;
      box-shadow: 0px 2px 4px #0000001a;
      background: var(--color-white);
    }

    .menu_item {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 7px;
      font-weight: 500;
      background: var(--color-white);
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        background: rgba(106, 90, 172, 0.3);
      }
      &.selected {
        color: var(--color-white);
        background: var(--color-primary-bold);
      }
      &:first-of-type {
        border-radius: 2px 2px 0px 0px;
      }
      &:last-of-type {
        border-radius: 0px 0px 2px 2px;
      }
    }
  }

  .ui_modal {
    position: relative;
    display: inline-flex;
    align-items: center;

    .popup_area {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 50;
      width: 520px;
      margin-top: 10px;
      transform: translateX(-50%);
      border: 1px solid #999ebf;
      border-radius: 2px;
      box-shadow: 0px 3px 12px #0000004d;
      background: var(--color-white);
    }
    .popup_title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      h4 {
        font-size: 16px;
        font-weight: 500;
      }
    }
    .popup_content {
      padding: 10px;
      border-top: 1px solid #999ebf;
    }
    .popup_close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      span {
        position: absolute;
        left: 6px;
        display: block;
        width: 18px;
        height: 1px;
        background-color: var(--color-primary-bold);
      }
      .line1 {
        top: 5px;
        transform: translateY(9px) rotate(-45deg);
      }
      .line2 {
        bottom: 5px;
        transform: translateY(-8px) rotate(45deg);
      }
    }
  }
  
  .ui_select, .ui_date, .ui_dropdown, .ui_modal {
    position: relative;

    .select_area {
      display: flex;
      align-items: center;
      min-width: 100px;
      width: 100%;
      height: 26px;
      padding: 0 28px 0 10px;
      border: 1px solid var(--color-primary-normal);
      border-radius: 3px;
      background-repeat: no-repeat;
      background-position: right 6px center;
      background-image: url('/images/icon_drop_down.svg');
      cursor: pointer;

      &.active {
        border: 1px solid var(--color-primary-bold);
        background-image: url('/images/icon_drop_up.svg');
      }

      .team_logo {
        width: 18px;
        margin-right: 5px;
      }
    }
  }

  .ui_table {
    width: 100%;
    tr {
      &.hover {
        cursor: pointer;
        &:hover {
          background: #EBE7FC;
          border: 2px solid #8573AC;
          box-sizing: border-box;
          td {
            border-top: 0;
            border-bottom: 0;
          }
        }
      }
    }
    th {
      min-width: 26px;
      height: 36px;
      padding: 0 6px;
      font-weight: 500;
      background: #eaeaef;
      border: 1px solid #ccccd4;
      border-bottom: 1px solid #535358;
      border-top: 0;
      line-height: 1.2;
      vertical-align: middle;
      &:first-of-type {
        border-left: 0;
        border-radius: 4px 0 0 0;
      }
      &:last-of-type {
        border-right: 0;
        border-radius: 0 4px 0 0;
      }
      &.small {
        font-size: 10px;
      }
      &.bdr_none {
        border-right: 0;
      }
      &.bdl_none {
        border-left: 0;
      }
    }
    td {
      position: relative;
      min-width: 26px;
      height: 32px;
      padding: 0 6px;
      border: 1px solid #ccccd4;
      text-align: center;
      vertical-align: middle;
      &.all {
        padding-left: 12px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 8px;
          height: 100%;
          background: #6dc9a7;
        }
      }
      &.blue {
        padding-left: 12px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 8px;
          height: 100%;
          background: #629ad2;
        }
      }
      &.red {
        padding-left: 12px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 8px;
          height: 100%;
          background: #eb8499;
        }
      }
      &.w80 {
        min-width: 80px;
        width: 80px;
      }
      &.w110 {
        min-width: 110px;
        width: 110px;
      }
      &.w760 {
        min-width: 760px;
        width: 760px;
      }
      &.more {
        padding: 0;
        cursor: pointer;
      }
    }
    .teams {
      display: flex;
      align-items: center;
    }
    .team_logo {
      width: 24px;
      margin-right: 14px;
    }
    .champs {
      display: flex;
      align-items: center;
      margin: 4px 0 2px;
      .img_champion {
        width: 28px;
      }
      p {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
      }
      li + li {
        margin-left: 6px;
      }
    }
    .champs_vs {
      display: flex;
      align-items: center;
      &.forward {
        p {
          font-weight: 400;
          text-align: left;
        }
      }
      &.reverse {
        p {
          font-weight: 700;
          text-align: right;
        }
      }
      .img_champion {
        width: 24px;
      }
      p {
        min-width: 90px;
        margin: 0 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
      }
    }
    .arrow {
      display: inline-block;
      width: 16px;
      height: 16px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url('/images/ico_list_expand01.svg');
    }
    .tr_normal {
      cursor: pointer;
      &.selected {
        border: 2px solid #8573ac;
        border-bottom: 0;
        td {
          background: #ebe7fc;
        }
        .arrow {
          background-image: url('/images/ico_list_expand02.svg');
        }
      }
    }
    .detail {
      border: 2px solid #8573ac;
      border-top: 0;
      border-bottom: 0;
      td {
        background: #ebe7fc;
      }
      &:last-of-type {
        border-bottom: 2px solid #8573ac;
      }
    }
    .detail + .tr_normal {
      border-top: 2px solid #8573ac;
    }
    .text_left {
      padding: 0 15px;
      text-align: left;
    }
  }

  .ui_table_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 10px;
        line-height: 1.5;
    .tabs {
      display: flex;
      align-items: center;
      button {
        min-width: 38px;
        padding: 2px 6px;
        color: rgba(49, 49, 49, 0.7);
        border-bottom: 2px solid transparent;
        &.selected {
          font-weight: 500;
          color: #6A5AAC;
          border-bottom-color: #6A5AAC;
        }
      }
    }
    .orders {
      display: flex;
      align-items: center;
      button {
        position: relative;
        margin: 0 5px;
        padding: 2px 6px;
        color: rgba(49, 49, 49, 0.7);
        &.selected {
          font-weight: 500;
          color: #6A5AAC;
        }
      }
      button + button {
        &::after {
          content: '';
          position: absolute;
          top:50%;
          left:-5px;
          margin-top:-6px;
          display: block;
          width: 1px;
          height: 12px;
          background: #A4A4B1;
        }
      }
    }
  }

  .ui_detail {
    .title {
      border-top: 1px solid #535358;
      background: #EAEAEF;
      padding: 10px 24px;
      h3 {
        font-size: 24px;
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;
      .left,
      .right,
      dl {
        display: flex;
        align-items: center;
      }
      dl + dl {
        margin-left: 16px;
      }
      dt {
        color: rgba(49, 49, 49, 0.7);
      }
      dd {
        margin-left: 6px;
      }
    }
    .content {
      border-top: 1px solid #535358;
      padding: 16px 10px 40px 24px;
      font-size: 14px;
      .scroll_y {
        min-height: 580px;
        max-height: 580px;
        overflow-y: auto;
        padding-right: 14px;
        ::-webkit-scrollbar {
          width: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #e0dcec;
          border-radius: 3px;
        }
        ::-webkit-scrollbar-track {
          background-color: #fff;
        }
      }
      p + p {
        margin-top: 12px;
      }
    }
    .anchor {
      border-top: 1px solid #535358;
      border-bottom: 1px solid #535358;
      dl {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        &.prev {
          dt::after {
            border-bottom: 4px solid #6A5AAB;
          }
        }
        &.next {
          dt::after {
            border-top: 4px solid #6A5AAB;
          }
        }
      }
      dl + dl {
        border-top: 1px solid #CCCCD4;
      }
      dt {
        position: relative;
        display: flex;
        align-items: center;
        width: 120px;
        font-weight: 500;
        color: #6A5AAC;
        &::before {
          content: '';
          display: block;
          width: 28px;
          height: 28px;
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 8px;
          width: 0px;
          height: 0px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
      dd {
        flex: 1;
        margin-left: 5px;
        color: #535358;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }
  
  .ui_winrate {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 85px;
    height: 18px;
    border-radius: 3px;
    .win {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      border-radius: 3px;
      text-align: left;
      font-weight: 500;
      text-indent: 6px;
      color: var(--color-white);
    }
    &.all {
      background: #c3ebd8;
      .win {
        background: #519f7d;
      }
    }
    &.blue {
      background: #d1e3f5;
      .win {
        background: #5684b1;
      }
    }
    &.red {
      background: #f8d9df;
      .win {
        background: #d17285;
      }
    }
  }

  .ui_pagination {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .page_btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin: 0 3px;
      background: var(--color-white);
      border: 1px solid #A4A4B1;
      border-radius: 6px;
      text-indent: -9999px;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        border-style: solid;
        border-color: #A4A4B1;
        border-width: 0;
      }
      &.first {
        &::after {
          width: 0;
          height: 0;
          margin-top: -5px;
          margin-left: -3px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right-width: 5px;
        }
      }
      &.prev {
        &::after {
          margin-top: -4px;
          margin-left: -3px;
          transform: rotate(225deg);
          border-top-width: 2px;
          border-right-width: 2px;
        }
      }
      &.next {
        &::after {
          margin-top: -4px;
          margin-left: -5px;
          transform: rotate(45deg);
          border-top-width: 2px;
          border-right-width: 2px;
        }
      }
      &.last {
        &::after {
          width: 0;
          height: 0;
          margin-top: -5px;
          margin-left: -2px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left-width: 5px;
        }
      }
      &:hover {
        background: #A4A4B1;
        border: 1px solid #535358;
        &::after {
          border-color: var(--color-white);
        }
        &.first {
          &::after {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }
        }
        &.last {
          &::after {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
          }
        }
      }
      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
    }
    
    .page_idx {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 17px;
      span, strong {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
      span {
        margin-left: 5px;
        color: rgba(49, 49, 49, 0.5);
      }
    }
  }
  
  .ui_box {
    display: flex;
    flex-direction: column;
    &_tit {
      background: #eaeaef;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid #535358;
      text-align: center;
      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 37px;
        font-size: 14px;
        font-weight: 500;
      }
      .ui_tooltip {
        margin: 7px 0 0 7px;
      }
    }

    &_con {
      flex: 1;
      padding: 15px;
      background: var(--color-white);
      border: 1px solid #ccccd4;
      border-top: 0;
      &.pd0 {
        padding: 0;
      }
      &.gry {
        background: #f7f6fb;
      }
    }

    &_con + &_tit {
      margin-top: 15px;
    }
  }
  
  .ui_gamedata {
    &.wards {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        display: flex;
        align-items: center;
      }
      span {
        display: inline-block;
        margin: 0 3px 0 2px;
      }
    }
    &.items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        width: 20px;
        height: 20px;
        margin: 1px;
      }
      .more {
        cursor: pointer;
      }
    }
    &.cs {
      span {
        font-size: 11px;
        color: rgba(49, 49, 49, 0.6);
      }
    }
    &.kda {
      p {
        font-size: 11px;
        color: rgba(49, 49, 49, 0.6);
      }
      span {
        font-size: 12px;
        color: rgba(49, 49, 49, 1);
        &.death {
          color: #c24a4a;
        }
      }
    }
    &.champs {
      display: flex;
      flex-direction: column;
      dl {
        display: flex;
        &.forward {
          margin-left: 7px;
          & + p {
            padding-left: 7px;
            text-align: left;
          }
          .level {
            left: 0;
          }
        }
        &.reverse {
          margin-right: 7px;
          & + p {
            padding-right: 7px;
            text-align: right;
          }
          .level {
            right: 0;
          }
        }
      }
      dt {
        position: relative;
        width: 42px;
        margin: 0 1px;
      }
      dd {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 20px;
        margin: 0 1px;
      }
      p {
        margin-top: 1px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
      }
      .level {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 15px;
        font-size: 10px;
        color: #fff;
        background: rgba(0,0,0,0.7);
        border: 1px solid #FFECEC;
      }
      .img_rune {
        width: 20px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.8);
      }
      .img_rune + .img_rune {
        padding: 2px;
      }
    }
  }
  
  .ui_tooltip {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    cursor: default;

    .select_area:hover {
      & + .view_area {
        display: block;
        opacity: 1;
      }
    }

    .view_area {
      position: absolute;
      left: 50%;
      bottom: 100%;
      z-index: 60;
      display: none;
      width: 350px;
      margin-bottom: 18px;
      padding: 15px;
      transform: translateX(-50%);
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 2px 6px #00000080;
      border: 2px solid #6a5aac;
      border-radius: 6px;
      &.small {
        width: 135px;
        margin-bottom: 12px;
        padding: 10px;
        border: 1px solid #999ebf;
        border-radius: 3px;
        &::before,
        &::after {
          display: none;
        }
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
      }
      &::before {
        bottom: -16px;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 16px solid #6a5aac;
      }
      &::after {
        bottom: -12px;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 12px solid var(--color-white);
      }
    }

    .tooltip_list_kda {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      li + li {
        margin-top: 3px;
      }
      b {
        display: inline-block;
        vertical-align: middle;
        margin: 0 9px;
        font-size: 14px;
      }
    }

    .tooltip_box_kill {
      text-align: left;
      h5 {
        margin-top: 13px;
        font-weight: 700;
        &:first-of-type {
          margin-top: 0;
        }
      }
      .kill_tbl {
        margin-top: 6px;
        dl {
          display: flex;
          width: fit-content;
          border: 1px solid #ccccd4;
        }
        dl + dl {
          border-top: 0;
        }
        dt,
        dd {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
          height: 32px;
        }
        dt {
          font-weight: 500;
          background: #eaeaef;
        }
        dd {
          font-weight: 400;
          background: var(--color-white);
          border-left: 1px solid #ccccd4;
        }
      }
    }

    .tooltip_box_exp {
      .bar_wrap {
        position: relative;
        display: flex;
        margin: 0 auto;
        &.w {
          width: 68px;
          margin-top: 12px;
          flex-direction: column;
          .bars {
            width: 100%;
            margin-top: 5px;
            border-bottom: 1px dashed #6a5aaa;
            .bar {
              position: relative;
              width: 14px;
              height: 100px;
              margin: 0 auto;
              background: #dedee1;
            }
          }
          .tit {
            margin-top: 4px;
            height: 16px;
            p {
              font-size: 10px;
              font-weight: 500;
            }
          }
        }
        &.h {
          width: 220px;
          height: 67px;
          margin-top: 25px;
          flex-direction: row-reverse;
          align-items: center;
          .bars {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0 7px 0 12px;
            border-left: 1px dashed #6a5aaa;
            .bar {
              position: relative;
              width: 143px;
              height: 18px;
              background: #dedee1;
            }
          }
          .tit {
            p {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .nn {
        .n1 {
          font-size: 10px;
          color: rgba(49, 49, 49, 0.6);
          span {
            font-size: 12px;
            color: #6a5aac;
          }
        }
        .n2 {
          font-size: 10px;
          color: rgba(49, 49, 49, 0.6);
          span {
            color: rgba(49, 49, 49, 1);
          }
        }
        .n3 {
          font-size: 10px;
          color: rgba(49, 49, 49, 0.6);
        }
      }
      .bars {
        position: relative;
        .bar {
          position: relative;
          background: #dedee1;
          i {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: #000;
            &.all {
              background: #6dc9a7;
            }
            &.blue {
              background: #7facc5;
            }
          }
        }
      }
      .exp1,
      .exp2 {
        position: absolute;
        bottom: -1px;
        display: flex;
        align-items: center;
        p {
          font-size: 10px;
          font-weight: 500;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 20px;
          height: 1px;
          background: #6b5ff0;
          box-sizing: border-box;
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
      }
      .exp1 {
        right: 95%;
        padding-right: 6px;
        border-right: 1px solid #6b5ff0;
        p {
          width: 90px;
        }
        &::before,
        &::after {
          right: -14px;
        }
      }
      .exp2 {
        left: 95%;
        padding-left: 6px;
        border-left: 1px solid #6b5ff0;
        p {
          width: 110px;
        }
        &::before,
        &::after {
          left: -14px;
        }
      }
    }

    .tooltip_box_formula {
      margin-top: 25px;
      text-align: left;
      h5 {
        margin: 0 7px;
        font-weight: 700;
      }
      .for_wrap {
        display: flex;
        align-items: flex-start;
        margin-top: 4px;
        padding: 15px 8px;
        background: #f5f3fc;
        border: 1px solid #6a5aac;
        .result {
          margin-top: 9px;
          color: rgba(49, 49, 49, 0.6);
          span {
            font-size: 16px;
            font-weight: 700;
            color: #6a5aac;
          }
        }
        .equal {
          margin: 13px 15px 0 5px;
          font-weight: 700;
        }
        .fountain {
          flex: 1;
          text-align: center;
          p {
            font-weight: 500;
          }
          p + p {
            margin-top: 4px;
            padding-top: 3px;
            border-top: 1px solid #707070;
          }
        }
      }
    }
  }

  .kda_badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 42px;
    height: 18px;
    font-weight: 500;
    color: var(--color-white);
    line-height: 1.5;
    border-radius: 3px;
    &.verybad {
      background: #9f9c9c;
    }
    &.bad {
      background: #e66e55;
    }
    &.normal {
      background: #df9462;
    }
    &.good {
      background: #ebb841;
    }
    &.verygood {
      background: #9cbe3b;
    }
    &.best {
      background: #3bbe8d;
    }
  }
  .kda_color {
    &.perfect {
      color: #F19000;
    }
    &.high {
      color: #F19000;
    }
    &.middle {
      color: #259A7E;
    }
    &.low {
      color: #69788D;
    }
  }
  .per_color {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 60px;
    height: 28px;
    border-radius: 6px;
    &.all {
      color: #65380a;
      background: #fff5d2;
    }
    &.blue {
      color: #9b1829;
      background: #ffd2d8;
    }
    &.red {
      color: #1f7d3e;
      background: #e2f5dd;
    }
  }
`;

export default GlobalStyles;
