import { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getSortedArray } from 'functions/common';
import { ImageObject, ImageChamp } from 'components/Image';

const TeamGold = ({ data }) => {
  const { plot_graph, timeline, end_time } = data;
  const timeStamp = getSortedArray(Object.values(plot_graph), 'timestamp');
  const goldDiff = getSortedArray(Object.values(plot_graph), 'gold_diff');
  // 블루팀 타임라인
  const blueObjectivesKey = Object.keys(timeline.blue.objectives);
  const blueObjectivesValue = Object.values(timeline.blue.objectives);
  const blueKillsKey = Object.keys(timeline.blue.kills);
  const blueKillsValue = Object.values(timeline.blue.kills);
  // 레드팀 타임라인
  const redObjectivesKey = Object.keys(timeline.red.objectives);
  const redObjectivesValue = Object.values(timeline.red.objectives);
  const redKillsKey = Object.keys(timeline.red.kills);
  const redKillsValue = Object.values(timeline.red.kills);

  const options = {
    chart: {
      type: 'areaspline',
      height: 360,
      spacingLeft: 0,
      spacingRight: 5,
      spacingTop: 15,
      spacingBottom: 18,
      events: {
        load: function () {
          const blueWoo = {
            marker: {
              radius: 5,
              width: 5,
              fillColor: '#5F82E2',
              lineWidth: 0,
            },
          };
          const redWoo = {
            marker: {
              radius: 5,
              width: 5,
              fillColor: '#F54A78',
              lineWidth: 0,
            },
          };
          const bothWoo = {
            marker: {
              radius: 5,
              width: 5,
              fillColor: '#3D3D3D',
              lineWidth: 0,
            },
          };
          const point = this.series[0].points;
          point.forEach((p, idx) => {
            if (p.y < 0) {
              p.update(redWoo);
            } else if (p.y > 0) {
              p.update(blueWoo);
            } else {
              p.update(bothWoo);
            }
          });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: timeStamp,
      gridLineWidth: 1,
      gridLineColor: '#A4A4B1',
      lineWidth: 1,
      lineColor: '#212121',
      tickInterval: 5,
      labels: {
        formatter: function () {
          const min = this.value / 60;
          return `${min}:00`;
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      gridLineWidth: 1,
      gridLineColor: '#C9D5E0',
      lineWidth: 1,
      lineColor: '#A4A4B1',
      minorGridLineDashStyle: 'dash',
      minorTickInterval: 'auto',
      minorTickWidth: 0,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.3,
        lineWidth: 1,
        softThreshold: false,
      },
      series: {
        animation: true,
        showInLegend: false,
        shadow: false,
        pointPlacement: 'on',
        marker: {
          enabled: true,
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        data: goldDiff,
        name: 'Gold Diff',
        zones: [
          {
            value: 0,
            color: 'rgba(245, 74, 120, 0.3)',
          },
          {
            color: 'rgba(95, 130, 226, 0.3)',
          },
        ],
      },
    ],
  };

  return (
    <>
      <div className="plotgraph">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="timeline">
        <div className="team blue">
          <dl className="line objective">
            <dt>Objective</dt>
            <dd>
              {blueObjectivesKey.map((item, index) => {
                const teamVal = blueObjectivesValue[index];
                const leftVal = (Number(item) / end_time) * 100;
                const widthVal = teamVal.length * 18 + teamVal.length * 2 - 2;
                const marginLeftVal = widthVal / 2;

                return (
                  <div
                    className="obj"
                    style={{
                      left: `${leftVal}%`,
                      width: `${widthVal}px`,
                      marginLeft: `-${marginLeftVal}px`,
                    }}
                    key={index}
                  >
                    {teamVal.map((item, index) =>
                      item.event_type === 'dragon' ? (
                        <ImageObject key={index} objId={item.victim_champ_id} dragon={true} />
                      ) : (
                        <ImageObject key={index} objId={item} dragon={false} />
                      )
                    )}
                  </div>
                );
              })}
            </dd>
          </dl>
          <dl className="line kill">
            <dt>Kill</dt>
            <dd>
              {blueKillsKey.map((item, index) => {
                const teamVal = blueKillsValue[index];
                const leftVal = (Number(item) / end_time) * 100;

                return <KillTooltip key={index} teamVal={teamVal} leftVal={leftVal} />;
              })}
            </dd>
          </dl>
        </div>
        <div className="team red">
          <dl className="line objective">
            <dt>Objective</dt>
            <dd>
              {redObjectivesKey.map((item, index) => {
                const teamVal = redObjectivesValue[index];
                const leftVal = (Number(item) / end_time) * 100;
                const widthVal = teamVal.length * 18 + teamVal.length * 2 - 2;
                const marginLeftVal = widthVal / 2;

                return (
                  <div
                    className="obj"
                    style={{
                      left: `${leftVal}%`,
                      width: `${widthVal}px`,
                      marginLeft: `-${marginLeftVal}px`,
                    }}
                    key={index}
                  >
                    {teamVal.map((item, index) =>
                      item.event_type === 'dragon' ? (
                        <ImageObject key={index} objId={item.victim_champ_id} dragon={true} />
                      ) : (
                        <ImageObject key={index} objId={item} dragon={false} />
                      )
                    )}
                  </div>
                );
              })}
            </dd>
          </dl>
          <dl className="line kill">
            <dt>Kill</dt>
            <dd>
              {redKillsKey.map((item, index) => {
                const teamVal = redKillsValue[index];
                const leftVal = (Number(item) / end_time) * 100;

                return <KillTooltip key={index} teamVal={teamVal} leftVal={leftVal} />;
              })}
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default TeamGold;

const KillTooltip = ({ teamVal, leftVal }) => {
  const [tooltipHide, setTooltipHide] = useState(true);

  return (
    <div
      className="kill"
      style={{
        left: `${leftVal}%`,
      }}
      onMouseEnter={() => {
        setTooltipHide(false);
      }}
      onMouseLeave={() => {
        setTooltipHide(true);
      }}
    >
      <span className={`circle size${teamVal.kill_count}`}></span>
      {!tooltipHide && (
        <div className="tooltip">
          <ul>
            {teamVal.kill_events.map((item, index) => (
              <li key={index}>
                <div className="killer">
                  <ImageChamp champId={item.killer_champ_id} />
                </div>
                <div className="versus">
                  <img src="/images/icon_battle.svg" alt="versus" />
                </div>
                <div className="victim">
                  <ImageChamp champId={item.victim_champ_id} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
