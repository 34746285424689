import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
  version: '',
  champions: [],
  runes: [],
  spells: {},
  items: {},
};

export const imageReducer = createSlice({
  name: 'image',
  initialState: initialStateValue,
  reducers: {
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setChampions: (state, action) => {
      state.champions = action.payload;
    },
    setRunes: (state, action) => {
      state.runes = action.payload;
    },
    setSpells: (state, action) => {
      state.spells = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setVersion, setChampions, setRunes, setSpells, setItems } = imageReducer.actions;
export default imageReducer.reducer;
