import { Link } from 'react-router-dom';

import { ImageChamp, ImageLane } from 'components/Image';
import { kdaBadge } from 'functions/common';

const PlayerRoster = ({ result }) => {
  const { player_stats_list } = result;

  return (
    <>
      <div className="player_roster">
        <div className="ui_bullet">
          <h3>Players</h3>
        </div>
        <div className="scroll_x">
          <table className="ui_table">
            <thead>
              <tr>
                <th className="normal">Role</th>
                <th className="normal">Player</th>
                <th className="normal">Games</th>
                <th className="normal">KDA</th>
                <th className="normal">CS/M</th>
                <th className="normal">KP %</th>
                <th className="normal">DMG %</th>
                <th className="normal">Gold %</th>
                <th className="normal">GD@15</th>
                <th className="normal">CSD@15</th>
                <th className="normal">Champions Playerd</th>
              </tr>
            </thead>
            <tbody>
              {player_stats_list.map((item, index) => (
                <tr key={index}>
                  <td>
                    <ImageLane laneId={item.role} /> {item.role}
                  </td>
                  <td>
                    <Link
                      to={`/players/statistics/${item.player_urn}/${item.role}/${item.player_name}`}
                    >
                      {item.player_name}
                    </Link>
                  </td>
                  <td>{item.games}</td>
                  <td>
                    <div className="ui_tooltip">
                      <div className="select_area">{kdaBadge(item.kda)}</div>
                      {/* <div className="view_area small">
                        <TooltipKda />
                      </div> */}
                    </div>
                  </td>
                  <td>{item.cspm}</td>
                  <td>{item.kp}%</td>
                  <td>{item.dmg_per}%</td>
                  <td>{item.gold_per}%</td>
                  <td>{item.gold_diff_15}</td>
                  <td>{item.cs_diff_15}</td>
                  <td className="w760">
                    <ul className="champs">
                      {item.played_champion_list.map((item, index) => (
                        <li key={index}>
                          <ImageChamp champId={item.champion_id} />
                          <p>{item.games}</p>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PlayerRoster;
