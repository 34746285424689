import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'store/features/user';
import { authService } from 'fbase';
import { HeaderContainer, IconUi } from 'styles/normalStyles';

const Header = () => {
  const el = useRef();
  const [infoToggle, setInfoToggle] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teamLogo = useSelector((state) => state.team.team_logo);
  const teamName = useSelector((state) => state.team.team_name);

  // 로그아웃
  const onLogOutClick = () => {
    let check = window.confirm('로그아웃 하시겠습니까?');
    if (check) {
      dispatch(logout());
      authService.signOut();
      navigate('/');
    } else {
      return null;
    }
  };

  // 토글 닫기
  const handleClickOutside = useCallback(
    (e) => {
      if (infoToggle && (!el.current || !el.current.contains(e.target))) setInfoToggle(false);
    },
    [infoToggle]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <HeaderContainer>
      <div className="header_logo">
        <div className="empty">
          <span>&nbsp;</span>
        </div>
        <h1 className="logo">
          <Link to="/matchhistory">
            <img src="/images/ico_logo_scrim.svg" alt="DEEPLOL SCRIM" />
          </Link>
        </h1>
      </div>
      <div className="header_util">
        {/* <Link to="/matchhistory">
          <span className="help">
            <IconUi
              className="icon_help"
              width={20}
              height={20}
              marginRight={8}
              name="icon_upload"
            />
            Scrim Upload
          </span>
        </Link> */}
        <div className="toggle">
          <span className="toggle_btn" onClick={() => setInfoToggle((prev) => !prev)}>
            <img src={teamLogo} alt={teamName} className="team_logo" />
          </span>
          {infoToggle && (
            <div className="toggle_panel" ref={el}>
              <button className="btn">
                <Link to="/teaminfomation">Team Infomation</Link>
              </button>
              <button className="btn" onClick={onLogOutClick}>
                Logout
                <IconUi
                  className="ico_logout"
                  width={12}
                  height={12}
                  marginRight={0}
                  name="ico_logout"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
