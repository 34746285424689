import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Spinner } from 'components';
import { Detail } from 'components/scrim';
import { SubDetailContainer } from 'styles/pageStyles';

const MatchDetail = () => {
  const userInfo = useSelector((state) => state.user.value);
  const { matchIdx } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState(false);

  // 디테일 데이터 가져오기
  const fetchDetail = useCallback(
    async ({ group_idx }) => {
      await axios
        .get(
          `${process.env.REACT_APP_SCRIM_DATA}v1/MatchHistory/detail?account_group_idx=${group_idx}&match_idx=${matchIdx}`
        )
        .then((res) => {
          setDetail(res.data);
        })
        .catch((err) => {
          alert('매치 정보가 없습니다.');
          navigate('/matchhistory', { replace: true });
        });
    },
    [matchIdx, navigate]
  );

  useEffect(() => {
    if (userInfo.group_idx !== '') {
      fetchDetail(userInfo);
    }
  }, [fetchDetail, userInfo]);

  return detail ? (
    <SubDetailContainer>
      <Detail detail={detail} />
    </SubDetailContainer>
  ) : (
    <Spinner />
  );
};

export default MatchDetail;
