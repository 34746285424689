import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { setVersion, setChampions, setRunes, setSpells, setItems } from 'store/features/image';
import { AppRouter } from 'components';
import { Wrap } from 'styles/normalStyles';

const App = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.value);
  const [init, setInit] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
  const getImageInfo = useCallback(
    async (version) => {
      let _thisGameVersionArr = version.split('.');
      let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

      const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
      const resRunes = await axios.get(
        `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resSpells = await axios.get(
        `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resItems = await axios.get(
        `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=ko_KR`
      );

      dispatch(setChampions(resChampions.data.champions));
      dispatch(setRunes(resRunes.data));
      dispatch(setSpells(resSpells.data.data));
      dispatch(setItems(resItems.data.data));
    },
    [dispatch]
  );

  // 게임 버전 스토어 등록
  const getGameVersion = useCallback(async () => {
    const res = await axios.get(`${process.env.REACT_APP_DDRAGON}api/versions.json`);
    dispatch(setVersion(res.data[0]));

    // 이미지 정보 함수호출
    getImageInfo(res.data[0]);
  }, [dispatch, getImageInfo]);

  useEffect(() => {
    // 로그인 확인
    if (userInfo.group_idx !== '') {
      setIsLoggedIn(true);
      getGameVersion();
    } else {
      setIsLoggedIn(false);
    }
    setInit(true);
  }, [getGameVersion, userInfo]);

  return (
    <>
      {init ? (
        <Wrap>
          <AppRouter isLoggedIn={isLoggedIn} />
        </Wrap>
      ) : null}
    </>
  );
};

export default App;
