import { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';

import { login } from 'store/features/user';
import { AuthContainer } from 'styles/pageStyles';

const Auth = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onChange = (e) => {
    const { value, name } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // log in
    await axios
      .get(`${process.env.REACT_APP_SCRIM_API}memberlogin?email=${email}&pwd=${password}`)
      .then((res) => {
        const data = res.data;
        if (data.code === 'error1') {
          alert(data.message);
        } else if (data.code === 'error2') {
          alert(data.message);
        } else if (data.code === 'success') {
          // 유저정보 dispatch in redux
          dispatch(login({ group_idx: data.group_idx, team_alias: data.team_alias }));
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error: member information does not exist.');
      });
  };

  return (
    <AuthContainer>
      <form className="form_login" onSubmit={onSubmit}>
        <h1>
          <img src="/images/ico_logo_scrim2.svg" alt="DEEPLOL SCRIM" />
        </h1>
        <div className="inp_wrap">
          <input
            type="email"
            value={email}
            onChange={onChange}
            name="email"
            placeholder="E-mail"
            autoCapitalize="off"
            required
          />
          <input
            type="password"
            value={password}
            onChange={onChange}
            name="password"
            placeholder="Password"
            required
          />
        </div>
        <div className="check_wrap">
          <div className="ui_form">
            <input
              type="checkbox"
              id="rememberme"
              name="rememberme"
              className="ui_inp_check"
              value="rememberme"
            />
            <label htmlFor="rememberme">Remember me</label>
          </div>
          {/* <span className="forgot">Forgot Password?</span> */}
        </div>
        <div className="submit_wrap">
          <button type="submit" className="submit">
            Log in
          </button>
          {/* <Link to="/signup">Sign up</Link> */}
        </div>
      </form>
    </AuthContainer>
  );
};

export default Auth;
