import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// 챔피언 이미지
export const ImageChamp = ({ champId }) => {
  const version = useSelector((state) => state.image.version);
  const imageSelector = useSelector((state) => state.image.champions);
  const [imgObj, setImgObj] = useState(false);

  useEffect(() => {
    if (imageSelector.filter((item) => item.championId === `${champId}`).length !== 0) {
      setImgObj(imageSelector.filter((item) => item.championId === `${champId}`)[0]);
    } else {
      setImgObj(false);
    }
  }, [champId, imageSelector]);

  return imgObj ? (
    <img
      src={`${process.env.REACT_APP_DDRAGON}cdn/${version}/img/champion/${imgObj.image_name}`}
      className="imgCell img_champion"
      alt={imgObj.champion_name_kr}
      title={imgObj.champion_name_kr}
    />
  ) : (
    <img src="/images/icon_cell_position_chap.svg" className="imgCell img_champion" alt="없음" />
  );
};

// 챔피언 이름
export const NameChamp = ({ champId }) => {
  const imageSelector = useSelector((state) => state.image.champions);
  const [imgObj, setImgObj] = useState(false);

  useEffect(() => {
    if (imageSelector.filter((item) => item.championId === `${champId}`).length !== 0) {
      setImgObj(imageSelector.filter((item) => item.championId === `${champId}`)[0]);
    } else {
      setImgObj(false);
    }
  }, [champId, imageSelector]);

  return imgObj ? imgObj.champion_name_en : null;
};

// 아이템 이미지 컴포넌트
export const ImageItem = ({ itemId }) => {
  const version = useSelector((state) => state.image.version);
  const imageSelector = useSelector((state) => state.image.items);
  const [imgObj, setImgObj] = useState(false);

  useEffect(() => {
    if (Object.keys(imageSelector).length !== 0) {
      let number = Object.entries(imageSelector).filter((item) => item[0] === `${itemId}`)[0];
      if (number !== undefined) {
        setImgObj(number[1]);
      }
    } else {
      setImgObj(false);
    }
  }, [imageSelector, itemId]);

  return (
    <>
      {imgObj ? (
        <img
          src={`${process.env.REACT_APP_DDRAGON}cdn/${version}/img/item/${imgObj.image.full}`}
          className="imgCell img_item"
          alt={imgObj.name}
          title={imgObj.name}
        />
      ) : (
        <div className="non_image">
          <img src="/images/icon_non_item.svg" className="imgCell img_item" alt="없음" />
        </div>
      )}
    </>
  );
};

// 룬 이미지 컴포넌트
export const ImageRune = ({ runeId, second }) => {
  const imageSelector = useSelector((state) => state.image.runes);
  const [imgObj, setImgObj] = useState(false);

  useEffect(() => {
    if (imageSelector.length !== 0) {
      for (const el of imageSelector) {
        if (el.id === runeId || el.key === runeId) {
          setImgObj(el);
        } else {
          const ids = el.slots[0].runes.filter((itm) => itm.id === runeId);
          if (ids.length !== 0) {
            setImgObj(ids[0]);
          } else {
            el.slots.forEach((slot) => {
              slot.runes.forEach((rune) => {
                if (rune.id === runeId) {
                  second ? setImgObj(el) : setImgObj(rune);
                }
              });
            });
          }
        }
      }
    } else {
      setImgObj(false);
    }
  }, [runeId, second, imageSelector]);

  return (
    <>
      {imgObj ? (
        <img
          src={`${process.env.REACT_APP_DDRAGON}cdn/img/${imgObj.icon}`}
          className="imgCell img_rune"
          alt={imgObj.name}
          title={imgObj.name}
        />
      ) : (
        <div className="non_image">
          <img src="/images/icon_non_item.svg" className="imgCell img_rune" alt="없음" />
        </div>
      )}
    </>
  );
};

// 스펠 이미지 컴포넌트
export const ImageSpell = ({ spellId }) => {
  const version = useSelector((state) => state.image.version);
  const imageSelector = useSelector((state) => state.image.spells);
  const [imgObj, setImgObj] = useState(false);
  const temp = spellId.split('.');

  useEffect(() => {
    if (Object.keys(imageSelector).length !== 0) {
      setImgObj(Object.values(imageSelector).filter((item) => item.id === `${temp[0]}`)[0]);
    } else {
      setImgObj(false);
    }
  }, [temp, imageSelector]);

  return imgObj ? (
    <img
      src={`${process.env.REACT_APP_DDRAGON}cdn/${version}/img/spell/${imgObj.image.full}`}
      className="imgCell img_spell"
      alt={imgObj.name}
      title={imgObj.name}
    />
  ) : (
    <div className="non_image">
      <img src="/images/icon_non_item.svg" className="imgCell img_spell" alt="아이템 없음" />
    </div>
  );
};

// 라인 이미지
export const ImageLane = ({ laneId, cell }) => {
  return cell ? (
    <img
      src={`/images/icon_cell_position_${laneId.toLowerCase()}.svg`}
      className="imgCell img_lane"
      alt={laneId}
      title={laneId}
    />
  ) : (
    <img src={`/images/icon_s_position_${laneId.toLowerCase()}_w.svg`} alt={laneId} />
  );
};

// 드래곤 이미지
export const ImageObject = ({ objId, dragon }) => {
  return dragon ? (
    <img
      src={`/images/icon_graph_dragon_${objId.toLowerCase()}_w.svg`}
      className="imgCell img_dragon"
      alt={objId}
      title={objId}
    />
  ) : objId.event_type !== 'tower' ? (
    <img
      src={`/images/icon_graph_${objId.event_type.toLowerCase()}_w.svg`}
      className="imgCell img_otherobj"
      alt={objId}
    />
  ) : null;
};
