import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import userReducer from 'store/features/user';
import imageReducer from 'store/features/image';
import teamReducer from 'store/features/team';

const reducers = combineReducers({
  user: userReducer,
  image: imageReducer,
  team: teamReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['image', 'team'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});
