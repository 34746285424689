import { useRef, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { IconUi } from 'styles/normalStyles';

// check시 value 더하기
const getAddValue = (arr) => {
  let num = 0;
  arr.forEach((el) => {
    if (el.checked) {
      num += Number(el.value);
    }
  });
  return num;
};

const Filter = ({ filters, fetchMain, isDetail }) => {
  const el = useRef();
  const userInfo = useSelector((state) => state.user.value);
  const teamInfo = useSelector((state) => state.team);
  const [filterState, setFilterState] = useState(filters);
  const [datePatch, setDatePatch] = useState('date');
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      // startDate: addDays(new Date(), -7),
      // endDate: new Date(),
      startDate: new Date(teamInfo.date.start_date),
      endDate: new Date(teamInfo.date.last_date),
      key: 'selection',
      color: 'rgba(106, 91, 169, 0.2)',
    },
  ]);
  const [openPatch, setOpenPatch] = useState(false);
  const [patch, setPatch] = useState(teamInfo.patch);
  const [openVs, setOpenVs] = useState(false);
  const [vsTeamList, setVsTeamList] = useState([]);
  const [vsTeam, setVsTeam] = useState('All');
  const [vsTeamLogo, setVsTeamLogo] = useState('//via.placeholder.com/56x56');
  const [checkedInputs, setCheckedInputs] = useState([
    'win',
    'lose',
    'blue',
    'red',
    '15m',
    '1530m',
    '30m',
  ]);

  // checkbox state관리
  const changeHandler = (checked, id) => {
    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
    } else {
      // 체크 해제
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
    }
  };

  // refresh
  const onRefresh = () => {
    setDatePatch('date');
    setDate([
      {
        startDate: new Date(teamInfo.date.start_date),
        endDate: new Date(teamInfo.date.last_date),
        key: 'selection',
        color: 'rgba(106, 91, 169, 0.2)',
      },
    ]);
    setPatch(teamInfo.patch);
    setVsTeam('All');
    setVsTeamLogo('//via.placeholder.com/56x56');
    setCheckedInputs(['win', 'lose', 'blue', 'red', '15m', '1530m', '30m']);
    setFilterState(filters);
  };

  // submit
  const onSubmit = (e) => {
    e.preventDefault();
    const { winlose, side, duration, datepatch } = e.target;

    if (isDetail) {
      const is_win = getAddValue(winlose);
      const select_side = getAddValue(side);
      const duration_interval = getAddValue(duration);

      fetchMain(userInfo, {
        is_win,
        select_side,
        duration_interval,
        start_date: datepatch[0].checked ? filterState.start_date : filters.start_date,
        end_date: datepatch[0].checked ? filterState.end_date : filters.end_date,
        patches: patch,
        vs_team_alias: vsTeam === 'All' ? '' : vsTeam,
      });
    } else {
      fetchMain(userInfo, {
        start_date: datepatch[0].checked ? filterState.start_date : filters.start_date,
        end_date: datepatch[0].checked ? filterState.end_date : filters.end_date,
        patches: patch,
        vs_team_alias: vsTeam === 'All' ? '' : vsTeam,
      });
    }
  };

  useEffect(() => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        start_date: format(date[0].startDate, 'yyyy-MM-dd'),
        end_date: format(date[0].endDate, 'yyyy-MM-dd'),
      };
    });
  }, [date]);

  // vs team 호출
  const getVersusTeamStats = useCallback(async () => {
    const { group_idx, team_alias } = userInfo;
    const res = await axios.get(
      `${process.env.REACT_APP_SCRIM_DATA}v1/TeamAsset/versus_team_stats?account_group_idx=${group_idx}&account_team_alias=${team_alias}`
    );
    setVsTeamList(res.data.team_stats);
  }, [userInfo]);

  const onSelectVsTeam = (team) => {
    if (team.team_name === 'All') {
      setVsTeam('All');
      setVsTeamLogo('//via.placeholder.com/56x56');
    } else {
      setVsTeam(team.team_alias);
      setVsTeamLogo(team.team_logo);
    }
    setOpenVs(false);
  };

  useEffect(() => {
    openVs && getVersusTeamStats();
  }, [getVersusTeamStats, openVs]);

  // 자동완성 닫기
  const handleClickOutside = useCallback(
    (e) => {
      if (openDate && (!el.current || !el.current.contains(e.target))) setOpenDate(false);
      if (openPatch && (!el.current || !el.current.contains(e.target))) setOpenPatch(false);
      if (openVs && (!el.current || !el.current.contains(e.target))) setOpenVs(false);
    },
    [openDate, openPatch, openVs]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <form className="forms" onSubmit={onSubmit}>
      <div className="form">
        <div className="row">
          <dl className={datePatch === 'date' ? 'active' : 'notactive'}>
            <dt>
              <div className="ui_form">
                <input
                  type="radio"
                  id="date"
                  name="datepatch"
                  className="ui_inp_radio"
                  value="date"
                  onChange={(e) => setDatePatch(e.currentTarget.id)}
                  checked={datePatch === 'date' ? true : false}
                />
                <label htmlFor="date">Date</label>
              </div>
            </dt>
            <dd>
              <div className="ui_date">
                <input
                  type="text"
                  className={`select_area ${openDate && 'active'}`}
                  value={`${format(date[0].startDate, 'yyyy-MM-dd')} ~ ${format(
                    date[0].endDate,
                    'yyyy-MM-dd'
                  )}`}
                  readOnly
                  onClick={() => setOpenDate((prev) => !prev)}
                />
                {openDate && (
                  <div className="menu_area" ref={el}>
                    <DateRangePicker
                      onChange={(item) => setDate([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={date}
                      direction="horizontal"
                    />
                  </div>
                )}
              </div>
            </dd>
          </dl>
          <dl className={datePatch === 'patch' ? 'active' : 'notactive'}>
            <dt>
              <div className="ui_form">
                <input
                  type="radio"
                  id="patch"
                  name="datepatch"
                  className="ui_inp_radio"
                  value="patch"
                  onChange={(e) => setDatePatch(e.currentTarget.id)}
                  checked={datePatch === 'patch' ? true : false}
                />
                <label htmlFor="patch">Patch</label>
              </div>
            </dt>
            <dd>
              <div className="ui_dropdown">
                <span
                  className={`select_area ${openPatch && 'active'}`}
                  onClick={() => setOpenPatch((prev) => !prev)}
                >
                  {patch.join(', ')}
                </span>
                {openPatch && (
                  <ul className="menu_area" ref={el}>
                    {teamInfo.patch.map((item, index) => {
                      console.log(item, index);
                      return (
                        <li key={index} className="menu_item">
                          <div className="ui_form">
                            <input
                              type="checkbox"
                              id={item}
                              name="version"
                              className="ui_inp_check"
                              value={item}
                              onChange={(e) =>
                                e.target.checked
                                  ? setPatch(patch.concat(e.target.value))
                                  : setPatch(patch.filter((itm) => itm !== e.target.value))
                              }
                              checked={patch.some((itm) => itm === item)}
                            />
                            <label htmlFor={item}>Patch {item}</label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </dd>
          </dl>
          <dl>
            <dt>Vs</dt>
            <dd>
              <div className="ui_modal">
                <span
                  className={`select_area ${openVs && 'active'}`}
                  onClick={() => setOpenVs((prev) => !prev)}
                >
                  <img src={vsTeamLogo} alt={vsTeam} className="team_logo" />
                  {vsTeam}
                </span>
                {openVs && (
                  <div className="popup_area" ref={el}>
                    <div className="popup_title">
                      <h4>Team</h4>
                    </div>
                    <div className="popup_content">
                      <table className="ui_table">
                        <colgroup>
                          <col width="*" />
                          <col width="58px" />
                          <col width="58px" />
                          <col width="58px" />
                          <col width="88px" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Teams</th>
                            <th>Games</th>
                            <th>Win</th>
                            <th>Lose</th>
                            <th>Win Rate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vsTeamList.map((team, index) => (
                            <tr
                              key={index}
                              className={
                                userInfo.team_alias !== team.team_alias ? 'hover' : 'no_hover'
                              }
                              onClick={() =>
                                userInfo.team_alias !== team.team_alias
                                  ? onSelectVsTeam(team)
                                  : false
                              }
                            >
                              <td>
                                <div className="teams">
                                  <img
                                    src={
                                      team.team_logo.length !== 0
                                        ? team.team_logo
                                        : '//via.placeholder.com/56x56'
                                    }
                                    alt={team.team_alias}
                                    className="team_logo"
                                  />
                                  {userInfo.team_alias !== team.team_alias
                                    ? `vs ${team.team_alias.length !== 0 ? team.team_alias : 'All'}`
                                    : 'My Team'}
                                </div>
                              </td>
                              <td>{team.game_count}</td>
                              <td>{team.win_count}</td>
                              <td>{team.lose_count}</td>
                              <td>
                                <div className="ui_winrate all">
                                  <span
                                    className="win"
                                    style={{ width: `${(team.win_rate * 100).toFixed(1)}%` }}
                                  >
                                    {(team.win_rate * 100).toFixed(1)}%
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="popup_close" onClick={() => setOpenVs(false)}>
                      <span className="line1"></span>
                      <span className="line2"></span>
                    </div>
                  </div>
                )}
              </div>
            </dd>
          </dl>
        </div>
        {isDetail && (
          <div className="row">
            <dl>
              <dt>W/L</dt>
              <dd>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="win"
                    name="winlose"
                    className="ui_inp_check"
                    value={1}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('win') ? true : false}
                  />
                  <label htmlFor="win">Win</label>
                </div>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="lose"
                    name="winlose"
                    className="ui_inp_check"
                    value={2}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('lose') ? true : false}
                  />
                  <label htmlFor="lose">Lose</label>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>Side</dt>
              <dd>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="blue"
                    name="side"
                    className="ui_inp_check"
                    value={1}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('blue') ? true : false}
                  />
                  <label htmlFor="blue">Blue</label>
                </div>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="red"
                    name="side"
                    className="ui_inp_check"
                    value={2}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('red') ? true : false}
                  />
                  <label htmlFor="red">Red</label>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>Duration</dt>
              <dd>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="15m"
                    name="duration"
                    className="ui_inp_check"
                    value={1}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('15m') ? true : false}
                  />
                  <label htmlFor="15m">~15m</label>
                </div>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="1530m"
                    name="duration"
                    className="ui_inp_check"
                    value={3}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('1530m') ? true : false}
                  />
                  <label htmlFor="1530m">15~30m</label>
                </div>
                <div className="ui_form">
                  <input
                    type="checkbox"
                    id="30m"
                    name="duration"
                    className="ui_inp_check"
                    value={5}
                    onChange={(e) => changeHandler(e.currentTarget.checked, e.currentTarget.id)}
                    checked={checkedInputs.includes('30m') ? true : false}
                  />
                  <label htmlFor="30m">30m~</label>
                </div>
              </dd>
            </dl>
            {/* <dl>
            <dt>Show</dt>
            <dd>
              <div className="ui_form">
                <input type="checkbox" id="chk8" name="chk" className="ui_inp_check" />
                <label htmlFor="chk8">Duration</label>
              </div>
              <div className="ui_form">
                <input type="checkbox" id="chk9" name="chk" className="ui_inp_check" />
                <label htmlFor="chk9">Bans</label>
              </div>
              <div className="ui_form">
                <input type="checkbox" id="chk10" name="chk" className="ui_inp_check" />
                <label htmlFor="chk10">Bans Vs</label>
              </div>
            </dd>
          </dl> */}
          </div>
        )}
      </div>
      <div className="btns">
        <button type="submit" className="ui_btn btn_pri">
          Search
        </button>
        {isDetail && (
          <button type="button" className="ui_btn btn_whi btn_refresh" onClick={onRefresh}>
            <IconUi
              className="ico_refresh_whi"
              width={16}
              height={16}
              marginRight={0}
              name="ico_refesh02"
            />
          </button>
        )}
      </div>
    </form>
  );
};

export default Filter;
