import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { ImageChamp, ImageLane } from 'components/Image';
import { IconUi } from 'styles/normalStyles';
import { TableView } from 'components/scrim';

const Table = ({ result, winRate, totalGames }) => {
  const [openId, setOpenId] = useState('');
  const [memo, setMemo] = useState(result);
  const [hideList, setHideList] = useState([]);
  const [currentList, setCurrentList] = useState(memo);

  // react table columns
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'match_date',
          },
        ],
        rowSpan: '2',
      },
      {
        Header: 'Patch',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'match_version',
          },
        ],
        rowSpan: '2',
      },
      {
        Header: 'Side',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'account_side',
          },
        ],
        rowSpan: '2',
      },
      {
        Header: 'W/L',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'is_win',
          },
        ],
        rowSpan: '2',
      },
      {
        Header: 'VS',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'vs_team',
            Cell: (item) => (
              <div className="teams">
                <img src={item.row.original.vs_team_logo} alt={item.value} className="team_logo" />
                <span>{item.value}</span>
              </div>
            ),
          },
        ],
        rowSpan: '2',
        accessor: 'vs_team',
      },
      {
        Header: 'Time',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'duration',
          },
        ],
        rowSpan: '2',
      },
      {
        id: 'bans',
        name: 'Bans',
        Header: () => (
          <div className="ban_pick">
            <span>Bans</span>
            <button>
              <img src="/images/ico_refesh.svg" alt="검색 초기화" title="검색 초기화" />
            </button>
          </div>
        ),
        columns: [
          {
            Header: (props) => (
              <BansPicks
                column={props.column}
                setFilter={props.setFilter}
                openId={openId}
                setOpenId={setOpenId}
              />
            ),
            Cell: ({ value }) => (
              <div className="col_box">
                {value.map((item, index) => (
                  <ImageChamp key={index} champId={item} />
                ))}
              </div>
            ),
            accessor: 'bans',
          },
        ],
      },
      {
        id: 'bansVs',
        name: 'Bans Vs',
        Header: () => (
          <div className="ban_pick">
            <span>Bans Vs</span>
            <button>
              <img src="/images/ico_refesh.svg" alt="검색 초기화" title="검색 초기화" />
            </button>
          </div>
        ),
        columns: [
          {
            Header: (props) => (
              <BansPicks
                column={props.column}
                setFilter={props.setFilter}
                openId={openId}
                setOpenId={setOpenId}
              />
            ),
            Cell: ({ value }) => (
              <div className="col_box">
                {value.map((item, index) => (
                  <ImageChamp key={index} champId={item} />
                ))}
              </div>
            ),
            accessor: 'bans_opp',
          },
        ],
      },
      {
        id: 'picks',
        name: 'Picks',
        Header: () => (
          <div className="ban_pick">
            <span>Picks</span>
            <button>
              <img src="/images/ico_refesh.svg" alt="검색 초기화" title="검색 초기화" />
            </button>
          </div>
        ),
        columns: [
          {
            Header: () => (
              <div className="col_box">
                <ImageLane laneId="top" cell={true} />
                <ImageLane laneId="jug" cell={true} />
                <ImageLane laneId="mid" cell={true} />
                <ImageLane laneId="bot" cell={true} />
                <ImageLane laneId="sup" cell={true} />
              </div>
            ),
            Cell: ({ value }) => (
              <div className="col_box">
                {value.map((item, index) => (
                  <ImageChamp key={index} champId={item} />
                ))}
              </div>
            ),
            accessor: 'picks',
          },
        ],
      },
      {
        id: 'picksVs',
        name: 'Picks Vs',
        Header: () => (
          <div className="ban_pick">
            <span>Picks Vs</span>
            <button>
              <img src="/images/ico_refesh.svg" alt="검색 초기화" title="검색 초기화" />
            </button>
          </div>
        ),
        columns: [
          {
            Header: () => (
              <div className="col_box">
                <ImageLane laneId="top" cell={true} />
                <ImageLane laneId="jug" cell={true} />
                <ImageLane laneId="mid" cell={true} />
                <ImageLane laneId="bot" cell={true} />
                <ImageLane laneId="sup" cell={true} />
              </div>
            ),
            Cell: ({ value }) => (
              <div className="col_box">
                {value.map((item, index) => (
                  <ImageChamp key={index} champId={item} />
                ))}
              </div>
            ),
            accessor: 'picks_opp',
          },
        ],
      },
      {
        Header: 'Players',
        columns: [
          {
            Header: () => (
              <div className="col_box lane">
                <ImageLane laneId="top" cell={false} />
                <span className="lane_name">Top</span>
              </div>
            ),
            Cell: ({ value }) => <span className="player">{value}</span>,
            accessor: 'top_player_name',
            disableFilters: true,
          },
          {
            Header: () => (
              <div className="col_box lane">
                <ImageLane laneId="jug" cell={false} />
                <span className="lane_name">Jug</span>
              </div>
            ),
            Cell: ({ value }) => <span className="player">{value}</span>,
            accessor: 'jug_player_name',
            disableFilters: true,
          },
          {
            Header: () => (
              <div className="col_box lane">
                <ImageLane laneId="mid" cell={false} />
                <span className="lane_name">Mid</span>
              </div>
            ),
            Cell: ({ value }) => <span className="player">{value}</span>,
            accessor: 'mid_player_name',
            disableFilters: true,
          },
          {
            Header: () => (
              <div className="col_box lane">
                <ImageLane laneId="bot" cell={false} />
                <span className="lane_name">Bot</span>
              </div>
            ),
            Cell: ({ value }) => <span className="player">{value}</span>,
            accessor: 'bot_player_name',
            disableFilters: true,
          },
          {
            Header: () => (
              <div className="col_box lane">
                <ImageLane laneId="sup" cell={false} />
                <span className="lane_name">Sup</span>
              </div>
            ),
            Cell: ({ value }) => <span className="player">{value}</span>,
            accessor: 'sup_player_name',
            disableFilters: true,
          },
        ],
      },
      {
        Header: '',
        columns: [
          {
            Header: 'Not visible header',
            displayNone: true,
            accessor: 'match_idx',
            disableFilters: true,
            Cell: ({ value }) => (
              <Link to={`${value}`} className="detailCell">
                <IconUi
                  className=""
                  width={20}
                  height={20}
                  marginRight={0}
                  name="icon_cell_search"
                />
              </Link>
            ),
          },
        ],
        rowSpan: '2',
        accessor: 'match_idx',
      },
    ],
    [openId]
  );

  const data = useMemo(() => memo, [memo]);

  return (
    <TableView
      columns={columns}
      data={data}
      winRate={winRate}
      totalGames={totalGames}
      setMemo={setMemo}
      hideList={hideList}
      setHideList={setHideList}
      currentList={currentList}
      setCurrentList={setCurrentList}
    />
  );
};

export default Table;

const BansPicks = ({ column, setFilter, openId, setOpenId }) => {
  const imageSelector = useSelector((state) => state.image.champions);
  const [allChampList, setAllChampList] = useState(imageSelector);
  const [champName, setChampName] = useState('');
  const [dummyChampionList] = useState([9999, 9999, 9999, 9999, 9999]);
  const [selectedTab, setSelectedTab] = useState('Recent');
  const [selectList, setSelectList] = useState([]);

  // search input에 값 입력시
  const handleChampName = async (e) => {
    const { value } = e.target;
    setChampName(value);
    if (value.length > 0) {
      await axios.get(`${process.env.REACT_APP_CHAMP_INFO}?term=${value}`).then((res) => {
        const data = res.data.champions;

        if (data.length !== 0) {
          setAllChampList(data);
        }
      });
    } else {
      setAllChampList(imageSelector);
    }
  };

  // 챔피언 추가
  const handleAddChamp = async ({ championId }) => {
    if (selectList.length < 5) {
      setSelectList([...selectList, championId]);
      setSelectList((prev) => prev.filter((item, index) => prev.indexOf(item) === index));
    }
  };

  // 챔피언 제거
  const handleDeleteChamp = (idx) => {
    setSelectList(selectList.filter((item) => item !== selectList[idx]));
  };

  const handleSubmit = () => {
    setOpenId('');
    setFilter(column.id, selectList);
    // setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
    // console.log(selectList);
  };

  return (
    <>
      <div className="col_box" onClick={() => setOpenId(column.id)}>
        <div className="dummyList">
          {dummyChampionList.map((item, index) => (
            <ImageChamp champId={item} key={index} />
          ))}
        </div>
        <div className="selectList">
          {selectList.map((item, index) => (
            <ImageChamp champId={item} key={index} />
          ))}
        </div>
      </div>
      {openId === column.id && (
        <div className="popup">
          <div className="popup_top">
            <h3>{column.parent.name}</h3>
            <div className="popup_close" onClick={() => setOpenId('')}>
              <span className="line1"></span>
              <span className="line2"></span>
            </div>
          </div>
          <div className="popup_selected">
            <div className="col_box">
              <div className="dummyList">
                {dummyChampionList.map((item, index) => (
                  <ImageChamp champId={item} key={index} />
                ))}
              </div>
              <ul className="selectList">
                {selectList.map((item, index) => (
                  <li key={index}>
                    <ImageChamp champId={item} />
                    <div className="delete" onClick={() => handleDeleteChamp(index)}>
                      <span className="line1"></span>
                      <span className="line2"></span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="popup_list">
            <div className="filter">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  value={champName}
                  onChange={handleChampName}
                />
                <button>
                  <IconUi width={16} height={16} marginRight={0} name="icon_input_search" />
                  {/* <IconUi width={16} height={16} marginRight={0} name="icon_input_del" /> */}
                </button>
              </div>
              <div className="tabs">
                <button
                  className={selectedTab === 'Recent' ? 'active' : 'none'}
                  onClick={() => setSelectedTab('Recent')}
                >
                  Recent
                </button>
                <button
                  className={selectedTab === 'All' ? 'active' : 'none'}
                  onClick={() => setSelectedTab('All')}
                >
                  All
                </button>
              </div>
            </div>
            <div className="result">
              {selectedTab === 'Recent' ? (
                <ul>
                  <li></li>
                </ul>
              ) : (
                <ul>
                  {allChampList.map((item, index) => (
                    <li key={index} onClick={() => handleAddChamp(item)}>
                      <ImageChamp champId={item.championId} />
                      <p>{item.champion_name_kr}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="popup_btns">
            <button className="ui_btn btn_pri" onClick={handleSubmit}>
              Apply
            </button>
            <button className="ui_btn btn_whi">Cancel</button>
          </div>
        </div>
      )}
    </>
  );
};
