import { useEffect, useState, useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { setLogo, setName, setDate, setPatch } from 'store/features/team';
import { NavigationContainer, IconUi } from 'styles/normalStyles';

const Navigation = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [depthToggle, setDepthToggle] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const userInfo = useSelector((state) => state.user.value);

  const onToggleNav = () => setNavToggle((prev) => !prev);
  const onToggleDepth = () => setDepthToggle((prev) => !prev);

  // 타이틀 데이터 가져오기
  const fetchTitle = useCallback(
    async ({ group_idx, team_alias }) => {
      const res = await axios.get(
        `${process.env.REACT_APP_SCRIM_DATA}v1/TeamAsset/title?account_group_idx=${group_idx}&account_team_alias=${team_alias}`
      );
      dispatch(setLogo(res.data.team_logo));
      dispatch(setName(res.data.team_name));

      const resDate = await axios.get(
        `${process.env.REACT_APP_SCRIM_DATA}sys/filter/date?group_idx=${group_idx}`
      );
      dispatch(setDate(resDate.data));

      const tempPatch = await axios.get(
        `${process.env.REACT_APP_SCRIM_DATA}sys/filter/patchVersion?group_idx=${group_idx}`
      );
      const resPatch = tempPatch.data.versions.split(',');
      dispatch(setPatch(resPatch));
    },
    [dispatch]
  );

  useEffect(() => {
    pathname.includes('players') ? setDepthToggle(true) : setDepthToggle(false);
    if (userInfo.group_idx !== '') {
      fetchTitle(userInfo);
    }
  }, [fetchTitle, pathname, userInfo]);

  return (
    <NavigationContainer className={`${navToggle && 'active'}`}>
      <button className={`toggle_gnb ${navToggle && 'active'}`} onClick={onToggleNav}>
        <span className="line1"></span>
        <span className="line2"></span>
        <span className="line3"></span>
      </button>
      <nav>
        <div className="depth01">
          <NavLink to="/notification" className="link">
            <IconUi
              className="icon_noti"
              width={24}
              height={24}
              marginRight={6}
              name="icon_mainmenu_noti"
            />
            <b>Notification</b>
          </NavLink>
        </div>
        {/* <div className="depth01">
          <NavLink to="/helpcenter" className="link">
            <IconUi
              className="icon_help"
              width={24}
              height={24}
              marginRight={6}
              name="icon_mainmenu_help"
            />
            <b>Help center</b>
          </NavLink>
        </div> */}
      </nav>
      <nav>
        <span className="dvs">Scrim</span>
        <div className="depth01">
          <NavLink to="/matchhistory" className="link">
            <IconUi
              className="icon_match"
              width={24}
              height={24}
              marginRight={6}
              name="icon_mainmenu_match"
            />
            <b>Match History</b>
          </NavLink>
        </div>
        <span className="dvs">Statistics</span>
        <div className="depth01">
          <NavLink to="/teams" className="link">
            <IconUi
              className="icon_team"
              width={24}
              height={24}
              marginRight={6}
              name="icon_mainmenu_team"
            />
            <b>Teams</b>
          </NavLink>
        </div>
        <div className="depth01">
          <span className={`link toggle_menu ${depthToggle && 'active'}`} onClick={onToggleDepth}>
            <IconUi
              className="icon_player"
              width={24}
              height={24}
              marginRight={6}
              name="icon_mainmenu_player"
            />
            <b>Players</b>
          </span>
          {depthToggle && (
            <div className="depth02">
              <NavLink to="/players/roster" className="link">
                <b>Roster</b>
              </NavLink>
              <NavLink to="/players/statistics" className="link">
                <b>Statistics</b>
              </NavLink>
            </div>
          )}
        </div>
      </nav>
    </NavigationContainer>
  );
};

export default Navigation;
