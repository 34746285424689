import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatNum } from 'functions/common';

const BarFO = ({ data, title }) => {
  const [series, setSeries] = useState([]);
  const [total, setTotal] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    let rate = [];
    let totalArr = [];
    let cntArr = [];
    for (const i in data) {
      if (i.includes('rate')) rate.push(formatNum(data[i]));
      if (i.includes('total')) totalArr.push(data[i]);
      if (i.includes('cnt')) cntArr.push(data[i]);
    }
    setSeries(rate);
    setTotal(totalArr);
    setCount(cntArr);
  }, [data]);

  const options = {
    chart: {
      type: 'column',
      height: 210,
      marginTop: 40,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [
        'Win Rate',
        'First Blood',
        'First Tower',
        'First Herald',
        'First Dragon',
        'First Nashor',
      ],
      gridLineDashStyle: 'dash',
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '10px',
          fontWeight: 500,
          color: '#313131',
        },
      },
    },
    yAxis: {
      title: {
        align: 'high',
        offset: 0,
        text: '(%)',
        rotation: 0,
        y: -12,
      },
      lineWidth: 1,
      lineColor: '#707070',
      gridLineWidth: 0,
      max: 100,
      labels: {
        x: -5,
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '10px',
          color: '#313131',
        },
      },
    },
    series: [
      {
        data: series,
        dataLabels: {
          enabled: true,
          useHTML: true,
          style: {
            fontFamily: 'Noto Sans KR',
            fontSize: '10px',
            color: '#313131',
          },
          formatter: function () {
            return `<div class="data_label"><strong>${this.y}</strong> %</div>`;
          },
        },
      },
    ],
    plotOptions: {
      series: {
        color: title === 'blue' ? '#6DBAEE' : title === 'red' ? '#E9A0AF' : '#6DC9A7',
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
          normal: {
            animation: false,
          },
        },
        point: {
          events: {
            mouseOver: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                        <dl>
                          <dt><span>${count[i]}</span> / ${total[i]}</dt>
                          <dd>Games</dd>
                        </dl>
                      </div>
                      `;
                  },
                },
              });
            },
            mouseOut: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                      </div>
                      `;
                  },
                },
              });
            },
          },
        },
      },
      column: {
        pointWidth: 14,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="bargraph">
      <div className="title">
        <h5 className={title}>{title}</h5>
        <p>
          ( <strong>{data.games}</strong> Games )
        </p>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarFO;
