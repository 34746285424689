import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Spinner, Filter } from 'components';
import { PlayStatistic } from 'components/statistics';
import { SubTitleContainer, SubFilterContainer, SubPlayersContainer } from 'styles/pageStyles';

const Statistics = () => {
  const { playerUrn, role, playerName } = useParams();
  const userInfo = useSelector((state) => state.user.value);
  const teamInfo = useSelector((state) => state.team);
  const [filters] = useState({
    start_date: '',
    end_date: '',
    patches: '',
    vs_team_alias: '',
  });
  const [result, setResult] = useState(false);

  const fetchMain = useCallback(
    async ({ group_idx, team_alias }, { start_date, end_date, patches, vs_team_alias }) => {
      const res = await axios.get(
        `${process.env.REACT_APP_SCRIM_DATA}v1/PlayerStatistics/main?account_group_idx=${group_idx}&account_team_alias=${team_alias}&start_date=${start_date}&end_date=${end_date}&patches=${patches}&vs_team_alias=${vs_team_alias}&player_urn=${playerUrn}&role=${role}`
      );
      setResult(res.data);
    },
    [playerUrn, role]
  );

  useEffect(() => {
    if (userInfo.group_idx !== '') {
      fetchMain(userInfo, filters);
    }
  }, [userInfo, filters, teamInfo, fetchMain]);

  return (
    <>
      <SubTitleContainer>
        <div className="logo">
          <img src={teamInfo.team_logo} alt={teamInfo.team_name} className="team_logo" />
        </div>
        <div className="name">
          <h3>{playerName}</h3>
        </div>
      </SubTitleContainer>
      <SubFilterContainer>
        {teamInfo.patch.length !== 0 && (
          <Filter filters={filters} fetchMain={fetchMain} isDetail={false} />
        )}
      </SubFilterContainer>
      {result ? (
        <SubPlayersContainer>
          <PlayStatistic result={result} />
        </SubPlayersContainer>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Statistics;
