import { Link } from 'react-router-dom';

import { ImageChamp, ImageLane } from 'components/Image';
import { kdaBadge } from 'functions/common';
import { BarABR, BarDP, BarDS, BarFN, BarFO } from 'components/charts';

const TeamStat = ({ result }) => {
  const { main_charts, economy, objectives, win_rate_for_first_event, players } = result;

  const formatNum = (num) =>
    num < 0 ? num.toFixed(2).toLocaleString('ko-KR') : '+' + num.toFixed(2).toLocaleString('ko-KR');

  return (
    <>
      <div className="team_summary">
        <table className="ui_table">
          <thead>
            <tr>
              <th className="normal">Side</th>
              <th className="normal">Games</th>
              <th className="normal">Win</th>
              <th className="normal">Lose</th>
              <th className="normal">Win Rate</th>
              <th className="normal">Time</th>
              <th className="normal">K</th>
              <th className="normal">D</th>
              <th className="normal">A</th>
              <th className="normal">KDA</th>
              <th className="normal">FB %</th>
              <th className="normal">FT %</th>
              <th className="normal">F3T %</th>
              <th className="normal">FHLD %</th>
              <th className="normal">FD %</th>
              <th className="normal">FBN %</th>
              <th className="normal">GD@15</th>
              <th className="normal">CSD@15</th>
              <th className="normal">KD@15</th>
              <th className="normal">TD@15</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="all">All</td>
              <td>{main_charts.stats_side_all.games}</td>
              <td>{main_charts.stats_side_all.wins}</td>
              <td>{main_charts.stats_side_all.losses}</td>
              <td className="w110">
                <div className="ui_winrate all">
                  <span
                    className="win"
                    style={{ width: `${(main_charts.stats_side_all.win_rate * 100).toFixed(1)}%` }}
                  >
                    {(main_charts.stats_side_all.win_rate * 100).toFixed(1)}%
                  </span>
                </div>
              </td>
              <td>{main_charts.stats_side_all.duration}</td>
              <td>{main_charts.stats_side_all.kills}</td>
              <td>{main_charts.stats_side_all.deaths}</td>
              <td>{main_charts.stats_side_all.assists}</td>
              <td>
                <div className="ui_tooltip">
                  <div className="select_area">{kdaBadge(main_charts.stats_side_all.kda)}</div>
                  {/* <div className="view_area small">
                    <TooltipKda />
                  </div> */}
                </div>
              </td>
              <td>{(main_charts.stats_side_all.fb * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_all.ft * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_all.f3t * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_all.fhld * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_all.fd * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_all.fbn * 100).toFixed(1)}%</td>
              <td>{main_charts.stats_side_all.gold_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_all.cs_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_all.kill_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_all.tower_diff_15.toFixed(1)}</td>
            </tr>
            <tr>
              <td className="blue">Blue</td>
              <td>{main_charts.stats_side_blue.games}</td>
              <td>{main_charts.stats_side_blue.wins}</td>
              <td>{main_charts.stats_side_blue.losses}</td>
              <td className="w110">
                <div className="ui_winrate blue">
                  <span
                    className="win"
                    style={{ width: `${(main_charts.stats_side_blue.win_rate * 100).toFixed(1)}%` }}
                  >
                    {(main_charts.stats_side_blue.win_rate * 100).toFixed(1)}%
                  </span>
                </div>
              </td>
              <td>{main_charts.stats_side_blue.duration}</td>
              <td>{main_charts.stats_side_blue.kills}</td>
              <td>{main_charts.stats_side_blue.deaths}</td>
              <td>{main_charts.stats_side_blue.assists}</td>
              <td>
                <div className="ui_tooltip">
                  <div className="select_area">{kdaBadge(main_charts.stats_side_blue.kda)}</div>
                  {/* <div className="view_area small">
                    <TooltipKda />
                  </div> */}
                </div>
              </td>
              <td>{(main_charts.stats_side_blue.fb * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_blue.ft * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_blue.f3t * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_blue.fhld * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_blue.fd * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_blue.fbn * 100).toFixed(1)}%</td>
              <td>{main_charts.stats_side_blue.gold_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_blue.cs_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_blue.kill_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_blue.tower_diff_15.toFixed(1)}</td>
            </tr>
            <tr>
              <td className="red">Red</td>
              <td>{main_charts.stats_side_red.games}</td>
              <td>{main_charts.stats_side_red.wins}</td>
              <td>{main_charts.stats_side_red.losses}</td>
              <td className="w110">
                <div className="ui_winrate red">
                  <span
                    className="win"
                    style={{ width: `${(main_charts.stats_side_red.win_rate * 100).toFixed(1)}%` }}
                  >
                    {(main_charts.stats_side_red.win_rate * 100).toFixed(1)}%
                  </span>
                </div>
              </td>
              <td>{main_charts.stats_side_red.duration}</td>
              <td>{main_charts.stats_side_red.kills}</td>
              <td>{main_charts.stats_side_red.deaths}</td>
              <td>{main_charts.stats_side_red.assists}</td>
              <td>
                <div className="ui_tooltip">
                  <div className="select_area">{kdaBadge(main_charts.stats_side_red.kda)}</div>
                  {/* <div className="view_area small">
                    <TooltipKda />
                  </div> */}
                </div>
              </td>
              <td>{(main_charts.stats_side_red.fb * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_red.ft * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_red.f3t * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_red.fhld * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_red.fd * 100).toFixed(1)}%</td>
              <td>{(main_charts.stats_side_red.fbn * 100).toFixed(1)}%</td>
              <td>{main_charts.stats_side_red.gold_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_red.cs_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_red.kill_diff_15.toFixed(1)}</td>
              <td>{main_charts.stats_side_red.tower_diff_15.toFixed(1)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ui_bullet">
        <h3>Economy</h3>
      </div>
      <div className="team_eo">
        <div className="tbl ui_box">
          <div className="ui_box_tit">
            <h4>Gold, CCSC, Turret</h4>
          </div>
          <div className="ui_box_con pd0">
            <dl>
              <dt>Gold Difference per min</dt>
              <dd>{formatNum(economy.gold_cs_turret.gold_diff_per_min)}</dd>
            </dl>
            <dl>
              <dt>Gold Difference at 15 min</dt>
              <dd>{formatNum(economy.gold_cs_turret.gold_diff_15_min)}</dd>
            </dl>
            <dl>
              <dt>CS Difference per min</dt>
              <dd>{formatNum(economy.gold_cs_turret.cs_diff_per_min)}</dd>
            </dl>
            <dl>
              <dt>CS Difference at 15 min</dt>
              <dd>{formatNum(economy.gold_cs_turret.cs_diff_15_min)}</dd>
            </dl>
            <dl>
              <dt>Turret Difference at 15 min</dt>
              <dd>{formatNum(economy.gold_cs_turret.turret_diff_15_min)}</dd>
            </dl>
            <dl>
              <dt>First Turret Control Rate</dt>
              <dd>
                <div className="ui_winrate all">
                  <span
                    className="win"
                    style={{
                      width: `${(economy.gold_cs_turret.first_turret_ctrl_rate * 100).toFixed(1)}%`,
                    }}
                  >
                    {(economy.gold_cs_turret.first_turret_ctrl_rate * 100).toFixed(1)}%
                  </span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>Win Rate for Gold Difference at 15min</h4>
          </div>
          <div className="ui_box_con pd0">
            <div className="diff_box">
              <div className="diff">
                <BarABR data={economy.stats_side_all} herald={false} title="all" />
              </div>
              <div className="diff">
                <BarABR data={economy.stats_side_blue} herald={false} title="blue" />
              </div>
              <div className="diff">
                <BarABR data={economy.stats_side_red} herald={false} title="red" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ui_bullet">
        <h3>Objectives</h3>
      </div>
      <div className="team_eo">
        <div className="tbl ui_box">
          <div className="ui_box_tit">
            <h4>Heralds, Dragons, Nashors</h4>
          </div>
          <div className="ui_box_con pd0">
            <dl>
              <dt>Heralds per game</dt>
              <dd>{objectives.heralds_dragons_nashors_list.heralds_per_game}</dd>
            </dl>
            <dl>
              <dt>1st Herald</dt>
              <dd>{objectives.heralds_dragons_nashors_list.herald_1}</dd>
            </dl>
            <dl>
              <dt>2nd Herald</dt>
              <dd>{objectives.heralds_dragons_nashors_list.herald_2}</dd>
            </dl>
            <dl>
              <dt>Dragons at 15 min</dt>
              <dd>{objectives.heralds_dragons_nashors_list.dragons_15_min}</dd>
            </dl>
            <dl>
              <dt>Nashors</dt>
              <dd>{objectives.heralds_dragons_nashors_list.nashors}</dd>
            </dl>
            <dl>
              <dt>Nashors Power Play Gold</dt>
              <dd>{formatNum(objectives.heralds_dragons_nashors_list.nashors_power_play_gold)}</dd>
            </dl>
          </div>
        </div>
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>
              Win Rate When Kill Herald
              <div className="ui_tooltip">
                <div className="select_area">
                  <i className="ico ico_help"></i>
                </div>
                <div className="view_area">
                  <div className="tooltip_box_kill">
                    <h5>- All Heralds Kill</h5>
                    <div className="kill_tbl">
                      <dl>
                        <dt>1st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                      </dl>
                      <dl>
                        <dt>2st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                      </dl>
                    </div>
                    <h5>- 1st Heralds Kill</h5>
                    <div className="kill_tbl">
                      <dl>
                        <dt>1st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                      </dl>
                      <dl>
                        <dt>2st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_x"></i>
                        </dd>
                        <dd>Expired</dd>
                      </dl>
                    </div>
                    <h5>- 2nd Heralds Kill</h5>
                    <div className="kill_tbl">
                      <dl>
                        <dt>1st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_x"></i>
                        </dd>
                      </dl>
                      <dl>
                        <dt>2st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_o"></i>
                        </dd>
                      </dl>
                    </div>
                    <h5>- No Heralds Kill</h5>
                    <div className="kill_tbl">
                      <dl>
                        <dt>1st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_x"></i>
                        </dd>
                        <dd>
                          <i className="ico ico_mark_x"></i>
                        </dd>
                        <dd>Expired</dd>
                      </dl>
                      <dl>
                        <dt>2st Herald</dt>
                        <dd>
                          <i className="ico ico_mark_x"></i>
                        </dd>
                        <dd>Expired</dd>
                        <dd>Expired</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="ui_box_con pd0">
            <div className="diff_box">
              <div className="diff">
                <BarABR
                  data={objectives.win_rate_when_kill_herald_side_all}
                  herald={true}
                  title="all"
                />
              </div>
              <div className="diff">
                <BarABR
                  data={objectives.win_rate_when_kill_herald_side_blue}
                  herald={true}
                  title="blue"
                />
              </div>
              <div className="diff">
                <BarABR
                  data={objectives.win_rate_when_kill_herald_side_red}
                  herald={true}
                  title="red"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="team_dww">
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>
              Dragon Priority
              <div className="ui_tooltip">
                <div className="select_area">
                  <i className="ico ico_help"></i>
                </div>
                <div className="view_area">
                  <div className="tooltip_box_exp">
                    <div className="bar_wrap w">
                      <div className="nn">
                        <p className="n1">
                          <span>NN</span> %
                        </p>
                        <p className="n2">
                          <span>NN</span> / NN
                        </p>
                      </div>
                      <div className="bars">
                        <div className="bar">
                          <i className="blue" style={{ height: '60%' }}></i>
                        </div>
                        <div className="exp1" style={{ height: '60%' }}>
                          <p>Killed by my team</p>
                        </div>
                        <div className="exp2" style={{ height: '100%' }}>
                          <p>
                            Killed by my team + <br />
                            Killed by enemy team
                          </p>
                        </div>
                      </div>
                      <div className="tit">
                        <p>Cloud</p>
                      </div>
                    </div>
                  </div>
                  <div className="tooltip_box_formula">
                    <h5>Dragon Priority</h5>
                    <div className="for_wrap">
                      <div className="result">
                        <span>NN.N</span> %
                      </div>
                      <div className="equal">=</div>
                      <div className="fountain">
                        <p>Killed by my team</p>
                        <p>
                          Killed by my team + <br />
                          Killed by enemy team
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="ui_box_con pd0">
            <BarDP data={objectives.dragon_priority} />
          </div>
        </div>
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>
              Win Rate When Dragon Soul
              <div className="ui_tooltip">
                <div className="select_area">
                  <i className="ico ico_help"></i>
                </div>
                <div className="view_area">
                  <div className="tooltip_box_exp">
                    <div className="bar_wrap w">
                      <div className="nn">
                        <p className="n1">
                          <span>NN</span> %
                        </p>
                        <p className="n2">
                          <span>NN</span> / NN
                        </p>
                        <p className="n3">Games</p>
                      </div>
                      <div className="bars">
                        <div className="bar">
                          <i className="blue" style={{ height: '100%' }}></i>
                        </div>
                      </div>
                      <div className="tit">
                        <p>Cloud</p>
                      </div>
                    </div>
                  </div>
                  <div className="tooltip_box_formula">
                    <h5>Win rate when obtaining dragon soul</h5>
                    <div className="for_wrap">
                      <div className="result">
                        <span>NN.N</span> %
                      </div>
                      <div className="equal">=</div>
                      <div className="fountain">
                        <p>Win games that obtained dragon soul</p>
                        <p>All games that obtained dragon soul</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="ui_box_con pd0">
            <BarDS data={objectives.win_rate_when_dragon_soul} />
          </div>
        </div>
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>
              Win Rate When First Nashor
              <div className="ui_tooltip">
                <div className="select_area">
                  <i className="ico ico_help"></i>
                </div>
                <div className="view_area">
                  <div className="tooltip_box_exp">
                    <div className="bar_wrap h">
                      <div className="nn">
                        <p className="n1">
                          <span>NN</span> %
                        </p>
                        <p className="n2">
                          <span>NN</span> / NN
                        </p>
                        <p className="n3">Games</p>
                      </div>
                      <div className="bars">
                        <div className="bar">
                          <i className="all" style={{ height: '100%' }}></i>
                        </div>
                      </div>
                      <div className="tit">
                        <p>All</p>
                      </div>
                    </div>
                  </div>
                  <div className="tooltip_box_formula">
                    <h5>Win Rate When First Nashor</h5>
                    <div className="for_wrap">
                      <div className="result">
                        <span>NN.N</span> %
                      </div>
                      <div className="equal">=</div>
                      <div className="fountain">
                        <p>Win games that killed first nashor</p>
                        <p>All games that killed first nashor</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="ui_box_con pd0">
            <BarFN data={objectives.win_rate_when_first_nashor} />
          </div>
        </div>
      </div>
      <div className="team_winrate">
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>
              Win Rate When First Objectives
              <div className="ui_tooltip">
                <div className="select_area">
                  <i className="ico ico_help"></i>
                </div>
                <div className="view_area">
                  <div className="tooltip_box_exp">
                    <div className="bar_wrap w">
                      <div className="nn">
                        <p className="n1">
                          <span>NN</span> %
                        </p>
                        <p className="n2">
                          <span>NN</span> / NN
                        </p>
                        <p className="n3">Games</p>
                      </div>
                      <div className="bars">
                        <div className="bar">
                          <i className="all" style={{ height: '100%' }}></i>
                        </div>
                      </div>
                      <div className="tit">
                        <p>
                          First <br />
                          Blood
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="tooltip_box_formula">
                    <div className="for_wrap">
                      <div className="result">
                        <span>NN.N</span> %
                      </div>
                      <div className="equal">=</div>
                      <div className="fountain">
                        <p>Win games when first blood</p>
                        <p>All games when first blood</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </h4>
          </div>
          <div className="ui_box_con pd0">
            <div className="diff_box">
              <div className="diff">
                <BarFO
                  data={win_rate_for_first_event.win_rate_when_first_objectives_side_all}
                  title="all"
                />
              </div>
              <div className="diff">
                <BarFO
                  data={win_rate_for_first_event.win_rate_when_first_objectives_side_blue}
                  title="blue"
                />
              </div>
              <div className="diff">
                <BarFO
                  data={win_rate_for_first_event.win_rate_when_first_objectives_side_red}
                  title="red"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ui_bullet">
        <h3>Players</h3>
      </div>
      <div className="scroll_x">
        <table className="ui_table">
          <thead>
            <tr>
              <th className="normal">Role</th>
              <th className="normal">Player</th>
              <th className="normal">Games</th>
              <th className="normal">KDA</th>
              <th className="normal">CS/M</th>
              <th className="normal">KP %</th>
              <th className="normal">DMG %</th>
              <th className="normal">Gold %</th>
              <th className="normal">GD@15</th>
              <th className="normal">CSD@15</th>
              <th className="normal">Champions Playerd</th>
            </tr>
          </thead>
          <tbody>
            {players.player_stat_list.map((item, index) => (
              <tr key={index}>
                <td>
                  <ImageLane laneId={item.role} /> {item.role}
                </td>
                <td>
                  <Link to={`/players/statistics/${item.player_urn}/${item.role}/${item.player}`}>
                    {item.player}
                  </Link>
                </td>
                <td>{item.games}</td>
                <td>
                  <div className="ui_tooltip">
                    <div className="select_area">{kdaBadge(item.kda)}</div>
                    {/* <div className="view_area small">
                      <TooltipKda />
                    </div> */}
                  </div>
                </td>
                <td>{item.csm.toFixed(1)}</td>
                <td>{(item.kp * 100).toFixed(1)}%</td>
                <td>{(item.dmg * 100).toFixed(1)}%</td>
                <td>{(item.gp * 100).toFixed(1)}%</td>
                <td>{item.gd_15.toFixed(1)}</td>
                <td>{item.csd_15.toFixed(1)}</td>
                <td className="w760">
                  <ul className="champs">
                    {item.champions_played.map((item, index) => (
                      <li key={index}>
                        <ImageChamp champId={item.champion_id} />
                        <p>{item.games}</p>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TeamStat;
