import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  ImageObject,
  ImageItem,
  ImageChamp,
  ImageRune,
  ImageSpell,
  ImageLane,
} from 'components/Image';
import { kdaColor } from 'functions/common';
import { TeamGold, TeamDTGCL } from 'components/charts';

const Detail = ({ detail }) => {
  const runeArr = useSelector((state) => state.image.runes);
  const { draft_order, economy, graph_all, graph_15, scoreboard, team_gold, team_stats, tracking } =
    detail;

  const economyList = Object.values(economy);
  const laneArray = ['top', 'jungle', 'mid', 'bottom', 'support'];
  const blueTeam = economyList.slice(0, 5);
  const redTeam = economyList.slice(5);

  const [switchGraph, setSwitchGraph] = useState(graph_all);
  const [buttonSelect, setButtonSelect] = useState('damage_dealt');
  const [trackingState, setTrackingState] = useState('first');
  const [modalOpen, setModalOpen] = useState(false);
  const [purchasedKeys, setPurchasedKeys] = useState(false);
  const [purchasedValues, setPurchasedValues] = useState(false);
  const [useRunes, setUseRunes] = useState(false);

  // 필터링
  const banFilter = (arr, idx) => {
    return {
      champ_id: arr.filter((item) => item.ban_order === idx)[0].champ_id,
      champ_name: arr.filter((item) => item.ban_order === idx)[0].champ_name,
    };
  };
  const pickFilter = (arr, idx) => {
    return {
      champ_id: arr.filter((item) => item.pick_order === idx)[0].champ_id,
      champ_name: arr.filter((item) => item.pick_order === idx)[0].champ_name,
      lane: arr.filter((item) => item.pick_order === idx)[0].lane,
    };
  };
  const runeFilter = (str) => {
    let result = '';
    switch (str) {
      case 'Domination':
        result = '지배';
        break;
      case 'Inspiration':
        result = '영감';
        break;
      case 'Precision':
        result = '정밀';
        break;
      case 'Resolve':
        result = '결의';
        break;
      case 'Sorcery':
        result = '마법';
        break;
      default:
    }

    return result;
  };

  const onChangeSwitch = (e) => {
    if (!e.target.checked) {
      setSwitchGraph(graph_all);
    } else {
      setSwitchGraph(graph_15);
    }
  };

  const handleItemPopup = (props) => {
    const keys = Object.keys(props.purchased_items);
    const values = Object.values(props.purchased_items);
    setPurchasedKeys(keys);
    setPurchasedValues(values);
    setUseRunes(props.runes);
    setModalOpen(true);
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [modalOpen]);

  return (
    <>
      <div className="detail_title">
        <div className="team forward">
          {/* 블루팀 */}
          <p className={scoreboard.blue_side_win_lose_string}>
            {scoreboard.blue_side_win_lose_string.toUpperCase()}
          </p>
          <h5 className="team_name">{scoreboard.blue_side.team_alias}</h5>
          <img
            src={scoreboard.blue_team_logo}
            alt={scoreboard.blue_side.team_alias}
            className="team_logo"
          />
        </div>
        <div className="center">
          <p className={scoreboard.blue_side_win_lose_string}>
            {scoreboard.blue_side.total_kill_score}
          </p>
          <b>VS</b>
          <p className={scoreboard.red_side_win_lose_string}>
            {scoreboard.red_side.total_kill_score}
          </p>
        </div>
        <div className="team reverse">
          {/* 레드팀 */}
          <p className={scoreboard.red_side_win_lose_string}>
            {scoreboard.red_side_win_lose_string.toUpperCase()}
          </p>
          <h5 className="team_name">{scoreboard.red_side.team_alias}</h5>
          <img
            src={scoreboard.red_team_logo}
            alt={scoreboard.red_side.team_alias}
            className="team_logo"
          />
        </div>
        <div className="bottom">
          <div className="box">
            <div className="obj forward">
              {scoreboard.blue_side.taken_dragon_list.split(',').map((item, index) => (
                <ImageObject objId={item} dragon={true} key={index} />
              ))}
            </div>
            <div className="time">
              <span>{scoreboard.game_duration}</span>
            </div>
            <div className="obj reverse">
              {scoreboard.red_side.taken_dragon_list.split(',').map((item, index) => (
                <ImageObject objId={item} dragon={true} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="detail_summary">
        <table className="ui_table">
          <thead>
            <tr>
              <th className="normal">Team</th>
              <th className="normal">K</th>
              <th className="normal">D</th>
              <th className="normal">A</th>
              <th className="normal">KDA</th>
              <th className="normal">FB</th>
              <th className="normal">FT</th>
              <th className="normal">F3T</th>
              <th className="normal">HLD#1</th>
              <th className="normal">HLD#2</th>
              <th className="normal">FD</th>
              <th className="normal">FBN</th>
              <th className="normal">GD@15</th>
              <th className="normal">CSD@15</th>
              <th className="normal">KD@15</th>
              <th className="normal">TD@15</th>
              <th className="small">
                Wards <br />
                Placed
              </th>
              <th className="small">
                Control <br />
                Wards <br />
                Purchased
              </th>
              <th className="small">
                Wards <br />
                Destroyed
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="blue">{team_stats.blue_side.team_alias}</td>
              <td>{team_stats.blue_side.kill}</td>
              <td>{team_stats.blue_side.death}</td>
              <td>{team_stats.blue_side.assist}</td>
              <td>{team_stats.blue_side.KDA.toFixed(2)}</td>
              <td>{team_stats.blue_side.FB && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.FT && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.F3T && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.HLD_1 && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.HLD_2 && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.FD && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.FBN && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.blue_side.GD_15 <= 0 ? null : '+' + team_stats.blue_side.GD_15}</td>
              <td>{team_stats.blue_side.CSD_15 <= 0 ? null : '+' + team_stats.blue_side.CSD_15}</td>
              <td>{team_stats.blue_side.KD_15 <= 0 ? null : '+' + team_stats.blue_side.KD_15}</td>
              <td>{team_stats.blue_side.TD_15 <= 0 ? null : '+' + team_stats.blue_side.TD_15}</td>
              <td>{team_stats.blue_side.ward_placed}</td>
              <td>{team_stats.blue_side.control_ward_purchased}</td>
              <td>{team_stats.blue_side.ward_destroyed}</td>
            </tr>
            <tr>
              <td className="red">{team_stats.red_side.team_alias}</td>
              <td>{team_stats.red_side.kill}</td>
              <td>{team_stats.red_side.death}</td>
              <td>{team_stats.red_side.assist}</td>
              <td>{team_stats.red_side.KDA.toFixed(2)}</td>
              <td>{team_stats.red_side.FB && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.FT && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.F3T && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.HLD_1 && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.HLD_2 && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.FD && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.FBN && <i className="ico ico_mark_o"></i>}</td>
              <td>{team_stats.red_side.GD_15 <= 0 ? null : '+' + team_stats.red_side.GD_15}</td>
              <td>{team_stats.red_side.CSD_15 <= 0 ? null : '+' + team_stats.red_side.CSD_15}</td>
              <td>{team_stats.red_side.KD_15 <= 0 ? null : '+' + team_stats.red_side.KD_15}</td>
              <td>{team_stats.red_side.TD_15 <= 0 ? null : '+' + team_stats.red_side.TD_15}</td>
              <td>{team_stats.red_side.ward_placed}</td>
              <td>{team_stats.red_side.control_ward_purchased}</td>
              <td>{team_stats.red_side.ward_destroyed}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="detail_ed">
        <div className="economy ui_box">
          <div className="ui_box_tit">
            <h4>Economy</h4>
          </div>
          <div className="ui_box_con">
            <div className="t_head">
              <div className="row">
                <div className="team forward">
                  <span className="t_col1">Wards</span>
                  <span className="t_col2">Item</span>
                  <span className="t_col3">CS</span>
                  <span className="t_col4">KDA</span>
                  <span className="t_col5">Rune/Spell</span>
                </div>
                <div className="center">&nbsp;</div>
                <div className="team reverse">
                  <span className="t_col1">Wards</span>
                  <span className="t_col2">Item</span>
                  <span className="t_col3">CS</span>
                  <span className="t_col4">KDA</span>
                  <span className="t_col5">Rune/Spell</span>
                </div>
              </div>
            </div>
            <div className="t_body">
              {laneArray.map((lane, index) => (
                <div className="row" key={index}>
                  <div className="team forward blue">
                    <div className="t_col1">
                      <div className="ui_gamedata wards">
                        <p>
                          <img src="/images/icon_mini_ward01.svg" alt="와드1" />
                          <span>{blueTeam[index].wards.control_ward_purchased}</span>
                        </p>
                        <p>
                          <img src="/images/icon_mini_ward02.svg" alt="와드2" />
                          <span>{blueTeam[index].wards.ward_placed}</span>
                        </p>
                        <p>
                          <img src="/images/icon_mini_ward03.svg" alt="와드3" />
                          <span>{blueTeam[index].wards.ward_destroyed}</span>
                        </p>
                      </div>
                    </div>
                    <div className="t_col2">
                      <div className="ui_gamedata items">
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_1.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_2.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_3.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_4.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_5.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_6.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={blueTeam[index].items.item_7.item_id} />
                        </span>
                        <span
                          className="item more"
                          onClick={() => handleItemPopup(blueTeam[index])}
                        >
                          <img src="/images/btn_item_build_search.svg" alt="아이템 더보기" />
                        </span>
                      </div>
                    </div>
                    <div className="t_col3">
                      <div className="ui_gamedata cs">
                        <p>{blueTeam[index].cs.cs}</p>
                        <p>
                          {blueTeam[index].cs.cspm.toFixed(1)} <span>/m</span>
                        </p>
                      </div>
                    </div>
                    <div className="t_col4">
                      <div className="ui_gamedata kda">
                        <p>
                          <span>{blueTeam[index].stats.kill}</span> /{' '}
                          <span className="death">{blueTeam[index].stats.death}</span> /{' '}
                          <span>{blueTeam[index].stats.assist}</span>
                        </p>
                        <p>{kdaColor(blueTeam[index].stats.kda.toFixed(2))}</p>
                      </div>
                    </div>
                    <div className="t_col5">
                      <div className="ui_gamedata champs">
                        <dl className="reverse">
                          <dt>
                            <ImageChamp champId={blueTeam[index].champion_id} />
                            <span className="level">{blueTeam[index].level}</span>
                          </dt>
                          <dd>
                            <ImageSpell spellId={blueTeam[index].spells.spell_1.spell_img} />
                            <ImageSpell spellId={blueTeam[index].spells.spell_2.spell_img} />
                          </dd>
                          <dd>
                            {<ImageRune runeId={blueTeam[index].runes.siblings.perk_1.rune_id} />}
                            {
                              <ImageRune
                                runeId={blueTeam[index].runes.siblings.perk_5.rune_id}
                                second={true}
                              />
                            }
                          </dd>
                        </dl>
                        <p>{blueTeam[index].player_name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="center">
                    <img src={`/images/icon_s_position_${lane}_w.svg`} alt={lane} />
                  </div>
                  <div className="team reverse red">
                    <div className="t_col1">
                      <div className="ui_gamedata wards">
                        <p>
                          <img src="/images/icon_mini_ward01.svg" alt="와드1" />
                          <span>{redTeam[index].wards.control_ward_purchased}</span>
                        </p>
                        <p>
                          <img src="/images/icon_mini_ward02.svg" alt="와드2" />
                          <span>{redTeam[index].wards.ward_placed}</span>
                        </p>
                        <p>
                          <img src="/images/icon_mini_ward03.svg" alt="와드3" />
                          <span>{redTeam[index].wards.ward_destroyed}</span>
                        </p>
                      </div>
                    </div>
                    <div className="t_col2">
                      <div className="ui_gamedata items">
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_1.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_2.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_3.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_4.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_5.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_6.item_id} />
                        </span>
                        <span className="item">
                          <ImageItem itemId={redTeam[index].items.item_7.item_id} />
                        </span>
                        <span className="item more" onClick={() => handleItemPopup(redTeam[index])}>
                          <img src="/images/btn_item_build_search.svg" alt="아이템 더보기" />
                        </span>
                      </div>
                    </div>
                    <div className="t_col3">
                      <div className="ui_gamedata cs">
                        <p>{redTeam[index].cs.cs}</p>
                        <p>
                          {redTeam[index].cs.cspm.toFixed(1)} <span>/m</span>
                        </p>
                      </div>
                    </div>
                    <div className="t_col4">
                      <div className="ui_gamedata kda">
                        <p>
                          <span>{redTeam[index].stats.kill}</span> /{' '}
                          <span className="death">{redTeam[index].stats.death}</span> /{' '}
                          <span>{redTeam[index].stats.assist}</span>
                        </p>
                        <p>{kdaColor(redTeam[index].stats.kda.toFixed(2))}</p>
                      </div>
                    </div>
                    <div className="t_col5">
                      <div className="ui_gamedata champs">
                        <dl className="forward">
                          <dt>
                            <ImageChamp champId={redTeam[index].champion_id} />
                            <span className="level">{redTeam[index].level}</span>
                          </dt>
                          <dd>
                            <ImageSpell spellId={redTeam[index].spells.spell_1.spell_img} />
                            <ImageSpell spellId={redTeam[index].spells.spell_2.spell_img} />
                          </dd>
                          <dd>
                            {<ImageRune runeId={redTeam[index].runes.siblings.perk_1.rune_id} />}
                            {
                              <ImageRune
                                runeId={redTeam[index].runes.siblings.perk_5.rune_id}
                                second={true}
                              />
                            }
                          </dd>
                        </dl>
                        <p>{redTeam[index].player_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="draftorder ui_box">
          <div className="ui_box_tit">
            <h4>Draft Order</h4>
          </div>
          <div className="ui_box_con pd0">
            <div className="title">
              <div className="team forward">
                <h5>{draft_order.blue_team.team_alias}</h5>
                <img
                  src={draft_order.blue_team.team_logo}
                  alt={draft_order.blue_team.team_alias}
                  className="team_logo"
                />
              </div>
              <div className="center">
                <b>VS</b>
              </div>
              <div className="team reverse">
                <h5>{draft_order.red_team.team_alias}</h5>
                <img
                  src={draft_order.red_team.team_logo}
                  alt={draft_order.red_team.team_alias}
                  className="team_logo"
                />
              </div>
            </div>
            <div className="result">
              <span className={`team ${draft_order.win_side === 100 ? 'win' : 'lose'}`}>
                {draft_order.win_side === 100 ? 'Win' : 'Lose'}
              </span>
              <span className={`team ${draft_order.win_side === 200 ? 'win' : 'lose'}`}>
                {draft_order.win_side === 200 ? 'Win' : 'Lose'}
              </span>
            </div>
            <div className="order">
              <div className="ban">
                <div className="row">
                  <div className="team forward">
                    <p>{banFilter(draft_order.blue_team.bans, 1).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.blue_team.bans, 1).champ_id} />
                  </div>
                  <div className="center">
                    <p>Ban</p>
                  </div>
                  <div className="team reverse">
                    <p>{banFilter(draft_order.red_team.bans, 2).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.red_team.bans, 2).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{banFilter(draft_order.blue_team.bans, 3).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.blue_team.bans, 3).champ_id} />
                  </div>
                  <div className="center">
                    <p>Ban</p>
                  </div>
                  <div className="team reverse">
                    <p>{banFilter(draft_order.red_team.bans, 4).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.red_team.bans, 4).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{banFilter(draft_order.blue_team.bans, 5).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.blue_team.bans, 5).champ_id} />
                  </div>
                  <div className="center">
                    <p>Ban</p>
                  </div>
                  <div className="team reverse">
                    <p>{banFilter(draft_order.red_team.bans, 6).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.red_team.bans, 6).champ_id} />
                  </div>
                </div>
              </div>
              <div className="pick">
                <div className="row">
                  <div className="team forward">
                    <p>{pickFilter(draft_order.blue_team.picks, 1).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.blue_team.picks, 1).champ_id} />
                  </div>
                  <div className="center">
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.blue_team.picks, 1).lane} />
                    </p>
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.red_team.picks, 2).lane} />
                    </p>
                  </div>
                  <div className="team reverse">
                    <p>{pickFilter(draft_order.red_team.picks, 2).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.red_team.picks, 2).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{pickFilter(draft_order.blue_team.picks, 4).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.blue_team.picks, 4).champ_id} />
                  </div>
                  <div className="center">
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.blue_team.picks, 4).lane} />
                    </p>
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.red_team.picks, 3).lane} />
                    </p>
                  </div>
                  <div className="team reverse">
                    <p>{pickFilter(draft_order.red_team.picks, 3).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.red_team.picks, 3).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{pickFilter(draft_order.blue_team.picks, 5).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.blue_team.picks, 5).champ_id} />
                  </div>
                  <div className="center">
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.blue_team.picks, 5).lane} />
                    </p>
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.red_team.picks, 6).lane} />
                    </p>
                  </div>
                  <div className="team reverse">
                    <p>{pickFilter(draft_order.red_team.picks, 6).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.red_team.picks, 6).champ_id} />
                  </div>
                </div>
              </div>
              <div className="ban">
                <div className="row">
                  <div className="team forward">
                    <p>{banFilter(draft_order.blue_team.bans, 8).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.blue_team.bans, 8).champ_id} />
                  </div>
                  <div className="center">
                    <p>Ban</p>
                  </div>
                  <div className="team reverse">
                    <p>{banFilter(draft_order.red_team.bans, 7).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.red_team.bans, 7).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{banFilter(draft_order.blue_team.bans, 10).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.blue_team.bans, 10).champ_id} />
                  </div>
                  <div className="center">
                    <p>Ban</p>
                  </div>
                  <div className="team reverse">
                    <p>{banFilter(draft_order.red_team.bans, 9).champ_name}</p>
                    <ImageChamp champId={banFilter(draft_order.red_team.bans, 9).champ_id} />
                  </div>
                </div>
              </div>
              <div className="pick">
                <div className="row">
                  <div className="team forward">
                    <p>{pickFilter(draft_order.blue_team.picks, 8).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.blue_team.picks, 8).champ_id} />
                  </div>
                  <div className="center">
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.blue_team.picks, 8).lane} />
                    </p>
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.red_team.picks, 7).lane} />
                    </p>
                  </div>
                  <div className="team reverse">
                    <p>{pickFilter(draft_order.red_team.picks, 7).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.red_team.picks, 7).champ_id} />
                  </div>
                </div>
                <div className="row">
                  <div className="team forward">
                    <p>{pickFilter(draft_order.blue_team.picks, 9).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.blue_team.picks, 9).champ_id} />
                  </div>
                  <div className="center">
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.blue_team.picks, 9).lane} />
                    </p>
                    <p>
                      <ImageLane laneId={pickFilter(draft_order.red_team.picks, 10).lane} />
                    </p>
                  </div>
                  <div className="team reverse">
                    <p>{pickFilter(draft_order.red_team.picks, 10).champ_name}</p>
                    <ImageChamp champId={pickFilter(draft_order.red_team.picks, 10).champ_id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="detail_ge">
        <div className="graph ui_box">
          <div className="ui_box_tit">
            <h4>Graph</h4>
          </div>
          <div className="ui_box_con">
            <div className="filter">
              <div className="btns">
                <button
                  type="button"
                  className={`ui_btn ${buttonSelect === 'damage_dealt' ? 'btn_pri' : 'btn_whi'}`}
                  onClick={() => setButtonSelect('damage_dealt')}
                >
                  Damage
                </button>
                <button
                  type="button"
                  className={`ui_btn ${buttonSelect === 'damage_taken' ? 'btn_pri' : 'btn_whi'}`}
                  onClick={() => setButtonSelect('damage_taken')}
                >
                  Taken
                </button>
                <button
                  type="button"
                  className={`ui_btn ${buttonSelect === 'player_gold' ? 'btn_pri' : 'btn_whi'}`}
                  onClick={() => setButtonSelect('player_gold')}
                >
                  Gold
                </button>
                <button
                  type="button"
                  className={`ui_btn ${buttonSelect === 'CS' ? 'btn_pri' : 'btn_whi'}`}
                  onClick={() => setButtonSelect('CS')}
                >
                  CS
                </button>
                <button
                  type="button"
                  className={`ui_btn ${buttonSelect === 'level' ? 'btn_pri' : 'btn_whi'}`}
                  onClick={() => setButtonSelect('level')}
                >
                  Level
                </button>
              </div>
              <div className="switch">
                15 min
                <label className="ui_switch">
                  <input type="checkbox" onChange={onChangeSwitch} />
                  <span className="onoff"></span>
                </label>
              </div>
            </div>
            <div className="panel">
              <TeamDTGCL data={switchGraph[buttonSelect]} title={buttonSelect} />
            </div>
          </div>
        </div>
        <div className="jungletracking ui_box">
          <div className="ui_box_tit">
            <h4>Early Jungle Tracking</h4>
          </div>
          <div className="ui_box_con">
            <div className="filter">
              <div className="ui_form">
                <input
                  type="radio"
                  id="first"
                  name="tracking"
                  className="ui_inp_radio"
                  onChange={(e) => setTrackingState(e.currentTarget.id)}
                  checked={trackingState === 'first' ? true : false}
                />
                <label htmlFor="first">0:00 ~ 4:00</label>
              </div>
              <div className="ui_form">
                <input
                  type="radio"
                  id="second"
                  name="tracking"
                  className="ui_inp_radio"
                  onChange={(e) => setTrackingState(e.currentTarget.id)}
                  checked={trackingState === 'second' ? true : false}
                />
                <label htmlFor="second">1:30 ~ 4:00</label>
              </div>
            </div>
            <div className="panel">
              {trackingState === 'first' && <img src={tracking.home_start_img} alt="0:00 ~ 4:00" />}
              {trackingState === 'second' && (
                <img src={tracking.camp_start_img} alt="1:30 ~ 4:00" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="detail_gold">
        <div className="teamgold ui_box">
          <div className="ui_box_tit">
            <h4>Team Gold</h4>
          </div>
          <div className="ui_box_con">
            <TeamGold data={team_gold} />
          </div>
        </div>
      </div>

      {modalOpen && (
        <div className="detail_popup">
          <div className="dimm" onClick={() => setModalOpen(false)}></div>
          <div className="content">
            <h3>아이템 빌드</h3>
            <div className="build items">
              {purchasedKeys.map((item, index) => (
                <div className="item" key={index}>
                  <dl>
                    <dt>
                      {purchasedValues[index].map((itm, idx) =>
                        itm.event_type !== 'UNDO_ITEM' ? (
                          <span
                            key={idx}
                            className={itm.event_type === 'SOLD_ITEM' ? 'sold' : 'normal'}
                          >
                            <ImageItem itemId={itm.item_id} />
                          </span>
                        ) : null
                      )}
                    </dt>
                    <dd>{item}분</dd>
                  </dl>
                </div>
              ))}
            </div>
            <h3>룬</h3>
            <div className="build runes">
              <div className="runes_first">
                <h5 className="tit">
                  <ImageRune runeId={useRunes.first_parent_rune_name} second={true} />
                  {runeFilter(useRunes.first_parent_rune_name)}
                </h5>
                <ul className="list">
                  {runeArr.map(
                    (item) =>
                      item.key === useRunes.first_parent_rune_name &&
                      item.slots.map((slot, idx) => (
                        <li key={idx}>
                          {slot.runes.map((rune, i) => (
                            <span
                              className={
                                useRunes.siblings.perk_1.rune_id === rune.id ||
                                useRunes.siblings.perk_2.rune_id === rune.id ||
                                useRunes.siblings.perk_3.rune_id === rune.id ||
                                useRunes.siblings.perk_4.rune_id === rune.id
                                  ? 'selected'
                                  : 'normal'
                              }
                              key={i}
                            >
                              <ImageRune runeId={rune.id} />
                            </span>
                          ))}
                        </li>
                      ))
                  )}
                </ul>
              </div>
              <div className="runes_second">
                <h5 className="tit">
                  <ImageRune runeId={useRunes.second_parent_rune_name} second={true} />
                  {runeFilter(useRunes.second_parent_rune_name)}
                </h5>
                <ul className="list">
                  {runeArr.map(
                    (item) =>
                      item.key === useRunes.second_parent_rune_name &&
                      item.slots.map((slot, idx) => (
                        <li key={idx}>
                          {slot.runes.map((rune, i) => (
                            <span
                              className={
                                useRunes.siblings.perk_5.rune_id === rune.id ||
                                useRunes.siblings.perk_6.rune_id === rune.id
                                  ? 'selected'
                                  : 'normal'
                              }
                              key={i}
                            >
                              <ImageRune runeId={rune.id} />
                            </span>
                          ))}
                        </li>
                      ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Detail;
