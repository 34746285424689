import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatNum } from 'functions/common';

const BarFN = ({ data }) => {
  const [series, setSeries] = useState([]);
  const [total, setTotal] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    let rate = [];
    let totalArr = [];
    let cntArr = [];
    for (const i in data) {
      if (i.includes('rate')) rate.push(formatNum(data[i]));
      if (i.includes('total')) totalArr.push(data[i]);
      if (i.includes('cnt')) cntArr.push(data[i]);
    }
    setSeries(rate);
    setTotal(totalArr);
    setCount(cntArr);
  }, [data]);

  const options = {
    chart: {
      type: 'bar',
      height: 210,
      marginTop: 30,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ['All', 'Blue', 'Red'],
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '16px',
          color: '#313131',
        },
      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      title: '',
    },
    series: [
      {
        data: series,
        colors: ['#6DC9A7', '#6DBAEE', '#E9A0AF'],
        dataLabels: {
          enabled: true,
          useHTML: true,
          style: {
            fontFamily: 'Noto Sans KR',
            fontSize: '10px',
            color: '#313131',
          },
          formatter: function () {
            return `<div class="data_label"><strong>${this.y}</strong> %</div>`;
          },
        },
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
          normal: {
            animation: false,
          },
        },
        point: {
          events: {
            mouseOver: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                        <dl>
                          <dt><span>${count[i]}</span> / ${total[i]}</dt>
                          <dd>Games</dd>
                        </dl>
                      </div>
                      `;
                  },
                },
              });
            },
            mouseOut: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                      </div>
                      `;
                  },
                },
              });
            },
          },
        },
      },
      bar: {
        colorByPoint: true,
        pointWidth: 18,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="bargraph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarFN;
