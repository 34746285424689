import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import {
  NotFound,
  Auth,
  MatchHistory,
  MatchDetail,
  SignUp,
  Notification,
  NotificationDetail,
  HelpCenter,
  Teams,
  Roster,
  Statistics,
} from 'routes';
import { Navigation, Header } from 'components';

import GlobalStyles from 'styles/globalStyles';
import { MainContainer, InnerContainer } from 'styles/normalStyles';

const AppRouter = ({ isLoggedIn }) => {
  return (
    <>
      <GlobalStyles />
      <Router>
        {isLoggedIn ? (
          <>
            <Header />
            <MainContainer>
              <Navigation />
              <InnerContainer>
                <Routes>
                  <Route index element={<Navigate replace to="/matchhistory" />} />
                  <Route path="/matchhistory/*" element={<MatchHistory />} />
                  <Route path="/matchhistory/:matchIdx" element={<MatchDetail />} />
                  <Route path="/notification/*" element={<Notification />} />
                  <Route path="/notification/:notiIdx" element={<NotificationDetail />} />
                  <Route path="/helpcenter" element={<HelpCenter />} />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/players/roster" element={<Roster />} />
                  <Route
                    path="/players/statistics/:playerUrn/:role/:playerName"
                    element={<Statistics />}
                  />
                  <Route
                    path="/players/statistics"
                    element={<Navigate replace to="/players/roster" />}
                  />
                  <Route path="/signup" element={<Navigate replace to="/matchhistory" />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </InnerContainer>
            </MainContainer>
          </>
        ) : (
          <Routes>
            <Route index element={<Auth />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Routes>
        )}
      </Router>
    </>
  );
};

export default AppRouter;
