import { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

import { authService, dbService } from 'fbase';

const SignUp = () => {
  const [team, setTeam] = useState('RGE');
  const [groupIdx, setGroupIdx] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onChangeSelect = (e) => {
    const { value } = e.currentTarget;
    setTeam(value);
  };

  const onChangeText = (e) => {
    const { value, name } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'groupIdx') {
      setGroupIdx(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let result;
    try {
      // create newAccount
      result = await createUserWithEmailAndPassword(authService, email, password);
    } catch (error) {
      setError(error.message);
    }

    await setDoc(doc(dbService, 'users', result.user.uid), {
      team_alias: team,
      group_idx: groupIdx,
      // sns_address: '',
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <select onChange={onChangeSelect} value={team}>
          <option value="RGE">RGE</option>
          <option value="DRX">DRX</option>
          <option value="DK">DK</option>
        </select>
        <input
          type="email"
          value={email}
          onChange={onChangeText}
          name="email"
          placeholder="Email"
          autoComplete="off"
          required
        />
        <input
          type="password"
          value={password}
          onChange={onChangeText}
          name="password"
          placeholder="Password"
          autoComplete="off"
          required
        />
        <input
          type="text"
          value={groupIdx}
          onChange={onChangeText}
          name="groupIdx"
          placeholder="group index"
          autoComplete="off"
          required
        />
      </div>
      <div>
        <button type="submit">Sign Up</button>
      </div>
      {error && <span className="authError">{error}</span>}
    </form>
  );
};

export default SignUp;
