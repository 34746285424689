import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const MainContainer = styled.main`
  flex: 1;
  display: flex;
`;

export const InnerContainer = styled.section`
  position: relative;
  flex: 1;
  margin: 30px 16px 60px;
  max-width: 1440px;
`;

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background: linear-gradient(180deg, #6a5aac 0%, #6a5ca8 100%);
  box-shadow: 0px 1px 3px #00000033;

  .header_logo {
    display: flex;
    align-items: center;
    .empty {
      width: 56px;
      height: 56px;
    }
    .logo {
      margin-left: 21px;
    }
  }

  .header_util {
    display: flex;
    align-items: center;
    padding: 0 12px;
    .help {
      display: flex;
      align-items: center;
      width: 138px;
      height: 36px;
      padding: 6px 9px;
      background: #69bfb7;
      border-radius: 6px;
      font-size: 14px;
      color: var(--color-white);
      .ico_upload {
        margin-right: 8px;
      }
    }
    .toggle {
      position: relative;
      &_btn {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 2px;
        margin-left: 8px;
        background: var(--color-white);
        border-radius: 3px;
        cursor: pointer;
      }
      &_panel {
        position: absolute;
        right: 0;
        top: 100%;
        min-width: 130px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 10px;
        background: #423c5d;
        box-shadow: 0px 3px 9px #00000080;
        .btn {
          display: flex;
          align-items: center;
          color: #ececec;
        }
        .btn + .btn {
          margin-top: 5px;
        }
        .ico_logout {
          margin-left: 4px;
        }
      }
    }
  }
`;

export const NavigationContainer = styled.aside`
  position: relative;
  width: 206px;
  background: linear-gradient(180deg, #f1f3f6 0%, #eceefb 100%);
  transition: width 0.2s ease;

  .toggle_gnb {
    position: absolute;
    top: -56px;
    left: 0;
    width: 56px;
    height: 56px;
    background: rgba(255, 255, 255, 0.15);
    span {
      position: absolute;
      left: 16px;
      display: block;
      width: 24px;
      height: 1px;
      background-color: var(--color-white);
      transition: all 0.2s;
    }
    .line1 {
      top: 20px;
    }
    .line2 {
      top: 27px;
    }
    .line3 {
      bottom: 20px;
    }
    &.active {
      .line1 {
        transform: translateY(8px) rotate(-45deg);
      }
      .line2 {
        opacity: 0;
      }
      .line3 {
        transform: translateY(-7px) rotate(45deg);
      }
    }
  }

  nav {
    padding: 18px 0;
  }
  nav + nav {
    border-top: 1px solid #dad9ef;
  }

  .dvs {
    display: block;
    margin: 12px 22px 8px 22px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(106, 90, 172, 0.5);
  }

  .depth01 {
    > .link {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      padding: 0 20px;
      b {
        font-size: 14px;
        color: var(--color-primary-bold);
      }
      &.active {
        background-color: var(--color-primary-bold);
        b {
          color: var(--color-white);
        }
        .icon_noti {
          background-image: url('/images/icon_mainmenu_noti_focus.svg');
        }
        .icon_help {
          background-image: url('/images/icon_mainmenu_help_focus.svg');
        }
        .icon_match {
          background-image: url('/images/icon_mainmenu_match_focus.svg');
        }
        .icon_team {
          background-image: url('/images/icon_mainmenu_team_focus.svg');
        }
        /* .icon_player {
          background-image: url('/images/icon_mainmenu_player_focus.svg');
        } */
      }
      &.toggle_menu {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: right 12px center;
        background-image: url('/images/icon_menu_in.svg');
        b {
          color: var(--color-primary-bold);
        }
        cursor: pointer;
        &.active {
          background-image: url('/images/icon_menu_down.svg');
        }
      }
    }
  }

  .depth02 {
    padding: 7px;
    margin: 0 9px;
    background: rgba(106, 90, 172, 0.14);
    border-radius: 6px;
    > .link {
      position: relative;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 20px 0 35px;
      font-size: 14px;
      color: var(--color-primary-bold);
      border-radius: 6px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 20px;
        width: 6px;
        height: 1px;
        background: var(--color-primary-bold);
      }
      &.active {
        color: var(--color-white);
        background: var(--color-primary-bold);
        &::before {
          background: var(--color-white);
        }
      }
    }
    &.active {
      display: flex;
      flex-direction: column;
    }
  }

  &.active {
    width: 55px;

    .dvs {
      display: none;
    }

    .depth01 {
      margin-top: 12px;
      padding: 0 10px;
      > .link {
        justify-content: center;
        width: 32px;
        height: 32px;
        padding: 0;
        border-radius: 7px;
        b {
          display: none;
        }
        i {
          margin-right: 0;
        }
        &.toggle_menu {
          background: none;
        }
      }
      &:first-of-type {
        margin-top: 0;
      }
    }

    .depth02 {
      margin: 0 -4px;
      padding: 0;
      > .link {
        height: 20px;
        padding: 0 3px;
        font-size: 10px;
        &::before {
          display: none;
        }
      }
    }

    .toggle_menu {
      background: none;
      &.active {
        background: none;
      }
    }
  }
`;

export const IconUi = styled.i`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  vertical-align: middle;
  font-size: 0;
  text-indent: -9999px;
  width: ${(props) => props.width + 'px'};
  height: ${(props) => props.height + 'px'};
  background-image: ${(props) => 'url(/images/' + props.name + '.svg)'};
  margin-right: ${(props) => props.marginRight + 'px'};
`;

export const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  min-height: 400px;

  img {
    width: 200px;
  }
`;
