import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Spinner, Filter } from 'components';
import { TeamStat } from 'components/statistics';
import { SubTitleContainer, SubFilterContainer, SubTeamsContainer } from 'styles/pageStyles';
// import { IconUi } from 'styles/normalStyles';

const Teams = () => {
  const userInfo = useSelector((state) => state.user.value);
  const teamInfo = useSelector((state) => state.team);
  const [filters] = useState({
    start_date: '',
    end_date: '',
    patches: '',
    vs_team_alias: '',
  });
  const [result, setResult] = useState(false);

  const fetchMain = async (
    { group_idx, team_alias },
    { start_date, end_date, patches, vs_team_alias }
  ) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SCRIM_DATA}v1/Team/main?account_group_idx=${group_idx}&account_team_alias=${team_alias}&start_date=${start_date}&end_date=${end_date}&patches=${patches}&vs_team_alias=${vs_team_alias}`
    );
    setResult(res.data);
  };

  useEffect(() => {
    if (userInfo.group_idx !== '') {
      fetchMain(userInfo, filters);
    }
  }, [userInfo, filters]);

  return (
    <>
      <SubTitleContainer>
        <div className="logo">
          <img src={teamInfo.team_logo} alt={teamInfo.team_name} className="team_logo" />
        </div>
        <div className="name">
          <h3>{teamInfo.team_name}</h3>
        </div>
        {/* <div className="btn">
          <button type="button" className="ui_btn btn_whi">
            <IconUi
              className="ico_change_team"
              width={20}
              height={20}
              marginRight={7}
              name="icon_change_team"
            />
            팀 변경
          </button>
        </div> */}
      </SubTitleContainer>
      <SubFilterContainer>
        {teamInfo.patch.length !== 0 && (
          <Filter filters={filters} fetchMain={fetchMain} isDetail={false} />
        )}
      </SubFilterContainer>
      {result ? (
        <SubTeamsContainer>
          <TeamStat result={result} />
        </SubTeamsContainer>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Teams;
