import { useState, useRef, useEffect, forwardRef } from 'react';
import { useTable, useRowSelect, usePagination, useFilters } from 'react-table';

const TableView = ({
  columns,
  data,
  winRate,
  totalGames,
  setMemo,
  hideList,
  setHideList,
  currentList,
  setCurrentList,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: ColumnFilter },
      initialState: { pageIndex: 0 },
    },
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div className="checkCell">
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className="checkCell">
              <i className={row.values.is_win === 'Win' ? 'win' : 'lose'}></i>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const [showNhide, setShowNhide] = useState('Hide');

  const hiddenRow = () => {
    // 체크된 데이터 리스트
    const checkedData = selectedFlatRows.map((d) => d.original);
    // 나머지 데이터 리스트
    const result = data.filter((item) => {
      return !checkedData.some((other) => other.match_idx === item.match_idx);
    });

    let flag = window.confirm('경기가 숨김 처리되었습니다.');
    if (flag) {
      setMemo(result);
      setCurrentList(result);
    } else {
      setMemo(data);
      setCurrentList(data);
    }

    setHideList(checkedData);
  };

  const hiddenView = () => {
    setShowNhide((prev) => (prev === 'Hide' ? 'Show' : 'Hide'));
    showNhide !== 'Hide' ? setMemo(currentList) : setMemo(hideList);
  };

  return (
    <>
      <div className="table_top">
        <div className="show_hide">
          {/* <button onClick={hiddenRow}> */}
          <button>
            <img
              src={`/images/icon_${showNhide.toLowerCase()}.svg`}
              alt={showNhide}
              title={showNhide}
            />
          </button>
          {/* <button onClick={hiddenView}> */}
          <button>
            <img src="/images/icon_view_list.svg" alt="Hidden List" title="Hidden List" />
          </button>
        </div>
        <div className="totals">
          <dl>
            <dt>Win Rate :</dt>
            <dd>{winRate}</dd>
          </dl>
          <dl>
            <dt>Total Games :</dt>
            <dd>{totalGames}</dd>
          </dl>
          <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} Games
              </option>
            ))}
          </select>
        </div>
      </div>

      <table {...getTableProps()}>
        <colgroup>
          <col width={36} />
          <col width={70} />
          <col width={42} />
          <col width={38} />
          <col width={38} />
          <col width={70} />
          <col width={50} />
          <col width={160} />
          <col width={160} />
          <col width={160} />
          <col width={160} />
          <col width={85} />
          <col width={85} />
          <col width={85} />
          <col width={85} />
          <col width={85} />
          <col width={34} />
        </colgroup>
        <thead>
          {headerGroups.map((headerGroup) => (
            // getHeaderGroupProps를 통해 header 배열을 호출한다
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // getHeaderProps는 각 셀 순서에 맞게 header를 호출한다
                <th
                  {...column.getHeaderProps()}
                  rowSpan={`${column.rowSpan ?? 1}`}
                  style={column.displayNone ? { display: 'none' } : {}}
                >
                  {column.render('Header')}
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              // getRowProps는 각 row data를 호출한다
              <tr {...row.getRowProps()} className={row.values.is_win === 'Win' ? 'win' : 'lose'}>
                {row.cells.map((cell) => {
                  let tdClassName = 'td';
                  if (cell.column.name) {
                    if (cell.column.name.includes('Bans') || cell.column.name.includes('Picks')) {
                      tdClassName += 'BansPicks';
                    }
                  }
                  // getCellProps는 각 cell data를 호출한다
                  return (
                    <td className={tdClassName} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {page.length === 0 && (
            <tr>
              <td colSpan={40}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="ui_pagination">
        <button className="page_btn first" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          처음으로
        </button>
        <button
          className="page_btn prev"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          이전으로
        </button>
        <div className="page_idx">
          <strong>{pageIndex + 1}</strong>
          <span>/{pageOptions.length}</span>
        </div>
        <button className="page_btn next" onClick={() => nextPage()} disabled={!canNextPage}>
          다음으로
        </button>
        <button
          className="page_btn last"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          마지막으로
        </button>
      </div>
    </>
  );
};

export default TableView;

const ColumnFilter = () => {
  return null;
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="ui_form single">
      <input type="checkbox" ref={resolvedRef} className="ui_inp_check" {...rest} />
      <label></label>
    </div>
  );
});
