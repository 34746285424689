import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { SubTitleContainer, SubNotificationContainer } from 'styles/pageStyles';

const NotificationDetail = () => {
  const { notiIdx } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(navigate, notiIdx);
  }, [navigate, notiIdx]);

  return (
    <>
      <SubTitleContainer>
        <div className="title">
          <h2>Notification</h2>
        </div>
      </SubTitleContainer>
      <SubNotificationContainer>
        <div className="ui_detail">
          <div className="title">
            <h3>Faker is Legend of Legend</h3>
          </div>
          <div className="info">
            <div className="left">
              <dl>
                <dt>Written by :</dt>
                <dd>Deeplol_Team</dd>
              </dl>
            </div>
            <div className="right">
              <dl>
                <dt>Reporting Date :</dt>
                <dd>2022-01-07</dd>
              </dl>
              <dl>
                <dt>View :</dt>
                <dd>244</dd>
              </dl>
            </div>
          </div>
          <div className="content">
            <div className="scroll_y">
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna…
              </p>
            </div>
          </div>
          <div className="anchor">
            <dl className="prev">
              <dt>Previous Post</dt>
              <dd>
                <Link to="">이전글의 제목이 이곳에 나타납니다.</Link>
              </dd>
            </dl>
            <dl className="next">
              <dt>Next Post</dt>
              <dd>
                <Link to="">다음글의 제목이 이곳에 나타납니다.</Link>
              </dd>
            </dl>
          </div>
          <div className="btns">
            <button className="ui_btn btn_whi">View list</button>
          </div>
        </div>
      </SubNotificationContainer>
    </>
  );
};

export default NotificationDetail;
