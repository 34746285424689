import { Link } from 'react-router-dom';

import { SubTitleContainer, SubNotificationContainer } from 'styles/pageStyles';

const Notification = () => {
  return (
    <>
      <SubTitleContainer>
        <div className="title">
          <h2>Notification</h2>
        </div>
      </SubTitleContainer>
      <SubNotificationContainer>
        <div className="ui_table_top">
          <div className="left">
            <div className="tabs">
              <button className="selected">All</button>
              <button>Patch</button>
            </div>
          </div>
          <div className="right">
            <div className="orders">
              <button className="selected">latest</button>
              <button>past</button>
            </div>
          </div>
        </div>
        <table className="ui_table">
          <colgroup>
            <col width="66px" />
            <col width="*" />
            <col width="180px" />
            <col width="87px" />
            <col width="165px" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Title</th>
              <th>Written by</th>
              <th>View</th>
              <th>Reporting Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3</td>
              <td>
                <div className="text_left">
                  <Link to="3">Faker is Legend of Legend</Link>
                </div>
              </td>
              <td>DeeplolTeam</td>
              <td>2,151</td>
              <td>53 min ago</td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                <div className="text_left">
                  <Link to="2">Faker is Legend of Legend</Link>
                </div>
              </td>
              <td>DeeplolTeam</td>
              <td>2,151</td>
              <td>53 min ago</td>
            </tr>
            <tr>
              <td>1</td>
              <td>
                <div className="text_left">
                  <Link to="1">Faker is Legend of Legend</Link>
                </div>
              </td>
              <td>DeeplolTeam</td>
              <td>2,151</td>
              <td>53 min ago</td>
            </tr>
          </tbody>
        </table>
        <div className="ui_pagination">
          <button className="page_btn first">처음으로</button>
          <button className="page_btn prev">이전으로</button>
          <div className="page_idx">
            <strong>1</strong>
            <span>/1</span>
          </div>
          <button className="page_btn next">다음으로</button>
          <button className="page_btn last">마지막으로</button>
        </div>
      </SubNotificationContainer>
    </>
  );
};

export default Notification;
