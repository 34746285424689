// kda 색상 바꾸기 (3 color)
export const kdaColor = (num) => {
  if (num >= 5) {
    return <span className="kda_color high">{num}</span>;
  } else if (num >= 3) {
    return <span className="kda_color middle">{num}</span>;
  } else {
    return <span className="kda_color low">{num}</span>;
  }
};

// kda 뱃지 만들기
export const kdaBadge = (num) => {
  if (num >= 5) {
    return <span className="kda_badge best">{num.toFixed(2)}</span>;
  } else if (num >= 4) {
    return <span className="kda_badge verygood">{num.toFixed(2)}</span>;
  } else if (num >= 3) {
    return <span className="kda_badge good">{num.toFixed(2)}</span>;
  } else if (num >= 2) {
    return <span className="kda_badge normal">{num.toFixed(2)}</span>;
  } else if (num >= 1) {
    return <span className="kda_badge bad">{num.toFixed(2)}</span>;
  } else {
    return <span className="kda_badge verybad">{num.toFixed(2)}</span>;
  }
};

// 배열객체를 prop기준 1배열로
export const getSortedArray = (arr, prop) => {
  let result = [];
  for (const item in arr) {
    result.push(arr[item][prop]);
  }
  return result;
};

export const formatNum = (num) => Math.round((num + Number.EPSILON) * 100);
