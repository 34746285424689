import { SpinnerContainer } from 'styles/normalStyles';

const Spinner = () => {
  return (
    <SpinnerContainer>
      <img src="/images/spinner.svg" alt="Loading..." />
    </SpinnerContainer>
  );
};

export default Spinner;
