import { createSlice } from '@reduxjs/toolkit';

const initialStateValue = {
  team_logo: '//via.placeholder.com/56x56',
  team_name: 'Team Name',
  date: '',
  patch: '',
};

export const teamReducer = createSlice({
  name: 'team',
  initialState: initialStateValue,
  reducers: {
    setLogo: (state, action) => {
      state.team_logo = action.payload;
    },
    setName: (state, action) => {
      state.team_name = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setPatch: (state, action) => {
      state.patch = action.payload;
    },
  },
});

export const { setLogo, setName, setDate, setPatch } = teamReducer.actions;
export default teamReducer.reducer;
