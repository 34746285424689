import { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatNum } from 'functions/common';

const BarDP = ({ data }) => {
  const [series, setSeries] = useState([]);
  const [total, setTotal] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    let rate = [];
    let totalArr = [];
    let cntArr = [];
    for (const i in data) {
      if (i.includes('rate')) rate.push(formatNum(data[i]));
      if (i.includes('total')) totalArr.push(data[i]);
      if (i.includes('cnt')) cntArr.push(data[i]);
    }
    setSeries(rate);
    setTotal(totalArr);
    setCount(cntArr);
  }, [data]);

  const options = {
    chart: {
      type: 'column',
      height: 230,
      marginTop: 40,
      spaceBottom: 15,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ['Cloud', 'Ocean', 'Infernal', 'Mountain', 'Hextech', 'Chemtech', 'Elder'],
      gridLineDashStyle: 'dash',
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '10px',
          fontWeight: 500,
          color: '#313131',
        },
        useHTML: true,
        formatter: function () {
          return `<p class="dragon_icon">
            <img src="/images/icon_graph_dragon_${this.value.toLowerCase()}_w.svg" />
            <span>${this.value}</span>
          </p>`;
        },
      },
    },
    yAxis: {
      title: {
        align: 'high',
        offset: 0,
        text: '(%)',
        rotation: 0,
        y: -12,
      },
      lineWidth: 1,
      lineColor: '#707070',
      gridLineWidth: 0,
      max: 100,
      labels: {
        x: -5,
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '10px',
          color: '#313131',
        },
      },
    },
    series: [
      {
        data: series,
        colors: ['#88B6DF', '#76BEA3', '#D86A8E', '#B67E33', '#828ECF', '#AFC459', '#9775B8'],
        zIndex: 10,
        dataLabels: {
          enabled: true,
          useHTML: true,
          style: {
            fontFamily: 'Noto Sans KR',
            fontSize: '10px',
            color: '#313131',
          },
          formatter: function () {
            return `<div class="data_label"><strong>${this.y}</strong> %</div>`;
          },
        },
      },
      {
        data: [100, 100, 100, 100, 100, 100, 100],
        colors: ['#DEDEE1', '#DEDEE1', '#DEDEE1', '#DEDEE1', '#DEDEE1', '#DEDEE1', '#DEDEE1'],
        zIndex: 5,
        color: 'rgba(0,0,0,0)',
      },
    ],
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
          normal: {
            animation: false,
          },
        },
        point: {
          events: {
            mouseOver: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                        <dl>
                          <dt><span>${count[i]}</span> / ${total[i]}</dt>
                          <dd>Games</dd>
                        </dl>
                      </div>
                      `;
                  },
                },
              });
            },
            mouseOut: function () {
              const point = this.series.data;
              const i = this.index;
              point[i].update({
                dataLabels: {
                  formatter: function () {
                    return `
                      <div class="data_label">
                        <strong>${point[i].y}</strong> %
                      </div>
                      `;
                  },
                },
              });
            },
          },
        },
      },
      column: {
        colorByPoint: true,
        grouping: false,
        pointWidth: 14,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="bargraph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarDP;
